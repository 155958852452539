import ApiService from './ApiService';

const AuthService = {
  login: async (email, password) => {
    const response = await ApiService.post('login', {
      email,
      password,
    });
    return response.data;
  },
  logout: async () => {
    const response = await ApiService.post('logout');
    return response.data;
  },
  forgotPassword: async (email) => {
    const response = await ApiService.post('forgot-password', {
      email,
    });
    return response.data;
  },
};

export default AuthService;
