import ApiService from './ApiService';

const StatisticService = {
  get: async () => {
    const response = await ApiService.get(`statistics`);
    return response.data;
  },
  getTopWebsites: async (timeFrame) => {
    const response = await ApiService.get(
      `statistics/top-websites` + `?timeframe=${timeFrame}`,
    );
    return response.data;
  },
};

export default StatisticService;
