import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Input } from '@material-tailwind/react';
import Card from 'components/card';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import CountryService from 'services/CountryService';

const HelperText = ({ text }) => {
  return <p className='mt-1 text-xs text-red-500'>{text}</p>;
};

const EditModal = ({ isOpen = false, onClose = null, modelId = null }) => {
  const { data, isFetching, isError } = useQuery(
    ['countries', modelId],
    async () => {
      return await CountryService.get(modelId);
    },
    {
      enabled: modelId != null,
      onSuccess: (data) => {
        setValue('name', data.countryname);
        setValue('countryCode', data.countrycode);
        setValue('code', data.code);
      },
    },
  );
  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm();
  const queryClient = useQueryClient();

  const updateCountryMutation = useMutation((data) =>
    CountryService.update(modelId, data),
  );

  const onSubmit = () => {
    if (!isDirty) {
      onClose();
      return;
    }
    const data = getValues();
    updateCountryMutation.mutate(
      {
        countryname: data.name,
        countrycode: data.countryCode,
        code: data.code,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('currencies');
          onClose();
          toast.success('Market updated successfully');
        },
        staleTime: 0,
        cacheTime: 0,
      },
    );
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen && !isFetching}
        onClose={() => {
          queryClient.invalidateQueries('countries');
          onClose();
        }}
        className='!z-[1010]'
      >
        <ModalOverlay className='z-40 bg-gray-800/70 backdrop-blur-sm backdrop-brightness-50' />
        <ModalContent className='!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]'>
          <ModalBody>
            <Card extra='px-[30px] pt-[35px] pb-[40px] min-w-[450px] flex flex-col !z-[1004]'>
              <h1 className='mb-[20px] text-2xl font-bold'>Edit market</h1>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-[20px] flex flex-col gap-y-4'>
                  <div>
                    <Input
                      className=''
                      variant='standard'
                      label='Name'
                      color={`${errors.name ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                      {...register('name', {
                        required: true,
                        minLength: 3,
                        maxLength: 50,
                      })}
                    />
                    {errors.name && errors.name.type === 'required' && (
                      <HelperText text='Name is required' />
                    )}
                    {errors.name && errors.name.type === 'minLength' && (
                      <HelperText text='Name must be at least 3 characters' />
                    )}
                  </div>
                  <div>
                    <Input
                      className=''
                      variant='standard'
                      label='Country code'
                      color={`${errors.code ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                      {...register('countryCode', {
                        required: true,
                        minLength: 3,
                        maxLength: 3,
                      })}
                    />
                    {errors.countryCode &&
                      errors.countryCode.type === 'required' && (
                        <HelperText text='Country code is required' />
                      )}
                    {errors.countryCode &&
                      errors.countryCode.type === 'minLength' && (
                        <HelperText text='Country code must be at least 3 characters' />
                      )}
                  </div>
                  <div>
                    <Input
                      className=''
                      variant='standard'
                      label='Code'
                      color={`${errors.code ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                      {...register('code', {
                        required: true,
                        minLength: 2,
                        maxLength: 2,
                      })}
                    />
                    {errors.code && errors.code.type === 'required' && (
                      <HelperText text='Code is required' />
                    )}
                    {errors.code && errors.code.type === 'minLength' && (
                      <HelperText text='Code must be at least 2 characters' />
                    )}
                  </div>
                </div>
                <div className='flex justify-end gap-2'>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onClose();
                    }}
                    className='linear w-1/2 rounded-xl px-5 py-2.5 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='linear w-1/2 rounded-xl bg-indigo-500 px-5 py-2.5 text-base font-medium text-white transition duration-200 hover:bg-indigo-600'
                  >
                    {updateCountryMutation.isLoading ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </form>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default EditModal;
