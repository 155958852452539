import { Navigate, Route, Routes } from 'react-router-dom';

import { useStoreState } from 'easy-peasy';
import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import { defaultQueryClientOptions, defaultToastOptions } from 'options';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalManager from 'ModalManager';
import { LazyMotion, domAnimation, m } from 'framer-motion';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { QueryParamProvider } from 'use-query-params';

const queryClient = new QueryClient({
  ...defaultQueryClientOptions,
});
const App = () => {
  const theme = useStoreState((state) => state.theme);
  return (
    <QueryClientProvider client={queryClient}>
      <LazyMotion features={domAnimation}>
        <div
          className={`${
            theme === 'dark' ? 'dark' : ''
          } text-sm text-gray-800 dark:text-gray-300`}
        >
          <ToastContainer {...defaultToastOptions} />
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Routes>
              <Route path='auth/*' element={<AuthLayout />} />
              <Route path='admin/*' element={<AdminLayout />} />
              <Route path='/' element={<Navigate to='/admin' replace />} />
              <Route path='*' element={<Navigate to='/404' replace />} />
            </Routes>
          </QueryParamProvider>
          <ModalManager />
        </div>
      </LazyMotion>
    </QueryClientProvider>
  );
};

export default App;
