import {
  Input,
  List,
  ListItem,
  Option,
  Select as ReactSelect,
  Step,
  Stepper,
} from '@material-tailwind/react';
import Card from 'components/card';
import Modal from 'components/modals/Modal';
import DateTimeHelper from 'helpers/DateTimeHelper';
import ErrorHelper from 'helpers/ErrorHelper';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiService from 'services/ApiService';
import { useDebounce } from 'use-debounce';
import CurrencySelector from './CurrencySelector';

const HelperText = ({ text }) => {
  return <p className='text-xs text-red-500'>{text}</p>;
};

const currencies = [
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'DKK',
    label: 'DKK',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'JPY',
    label: 'JPY',
  },
  {
    value: 'CHF',
    label: 'CHF',
  },
];

const LinkEditModal = ({ onClose = null, modelId = null, dealId = null }) => {
  // useEffect(() => {
  //   if (isOpen) {
  //     setActiveStep(0);
  //     setWebsiteQuery(null);
  //     setMarketQuery(null);
  //     return reset();
  //   }
  //   setValue('website', null);
  //   setWebsiteQuery(null);
  //   setValue('market', null);
  //   setMarketQuery(null);
  // }, [isOpen]);
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    getValues,
    setValue,
    trigger,
    formState: { errors, isDirty },
  } = useForm();
  const queryClient = useQueryClient();

  const createLinkMutation = useMutation((data) => {
    return ApiService.post('links', data);
  });
  const updateLinkMutation = useMutation((data) => {
    return ApiService.put(`links/${modelId}`, data);
  });
  const [websiteQuery, setWebsiteQuery] = useState(null);
  const [websiteQueryValue] = useDebounce(websiteQuery, 400);
  // const [selectedWebsite, setSelectedWebsite] = useState(null);

  const [marketQuery, setMarketQuery] = useState(null);
  const [marketQueryValue] = useDebounce(marketQuery, 400);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [liveMonth, setLiveMonth] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);

  useEffect(() => {
    register('website', { required: true });
    register('market', { required: true });
  }, []);

  const [selectedCurrency, setSelectedCurrency] = useState('EUR');
  const handleCurrencyChange = (currency) => {
    setSelectedCurrency(currency);
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      // validate inputs
      await trigger(['referring_site', 'target_url', 'category', 'website']);
      // check if errors is not equal to {}
      if (Object.keys(errors).length > 0) {
        return;
      }
      if (!getValues('website') || !getValues('market')) {
        return;
      }
    }
    !isLastStep && setActiveStep((cur) => cur + 1);
  };
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  const onSubmit = () => {
    const toastId = toast.loading(
      modelId ? 'Saving link...' : 'Creating link...',
    );
    if (modelId) {
      return updateLinkMutation.mutate(
        {
          website: getValues('website'),
          market: getValues('market'),
          referring_site: getValues('referring_site'),
          target_url: getValues('target_url'),
          price: getValues('price'),
          anchor_text: getValues('anchor_text'),
          discount_percentage: getValues('discount_percentage'),
          live_month: liveMonth,
          category: getValues('category'),
        },
        {
          onSuccess: () => {
            reset();
            setActiveStep(0);
            queryClient.invalidateQueries(`deals/${dealId}/links`);
            queryClient.invalidateQueries(`links`);
            onClose();
            toast.update(toastId, {
              render: 'Link updated successfully',
              type: 'success',
              isLoading: false,
              autoClose: 3000,
            });
          },
          onError: (error) => {
            let message = ErrorHelper.handleApiError(
              error.response.status,
              toastId,
            );
            if (error.response && error.response.status === 422) {
              return ErrorHelper.handleValidationErrors(
                error.response.data.errors,
                toastId,
              );
            }
            return toast.update(toastId, {
              render: message,
              type: 'error',
              isLoading: false,
              autoClose: 5000,
            });
          },
        },
      );
    }
    createLinkMutation.mutate(
      {
        website: getValues('website'),
        market: getValues('market'),
        referring_site: getValues('referring_site'),
        target_url: getValues('target_url'),
        price: getValues('price'),
        anchor_text: getValues('anchor_text'),
        live_month: liveMonth,
        deal_id: dealId,
        currency: selectedCurrency,
        ...data,
      },
      {
        onSuccess: () => {
          reset();
          setActiveStep(0);
          queryClient.invalidateQueries(`deals/${dealId}/links`);
          onClose();
          toast.update(toastId, {
            render: 'Link created successfully',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
        },
        onError: (error) => {
          let message = ErrorHelper.handleApiError(
            error.response.status,
            toastId,
          );
          if (error.response && error.response.status === 422) {
            return ErrorHelper.handleValidationErrors(
              error.response.data.errors,
              toastId,
            );
          }
          return toast.update(toastId, {
            render: message,
            type: 'error',
            isLoading: false,
            autoClose: 5000,
          });
        },
      },
    );
  };

  const {
    data: websites,
    isFetching,
    isLoading,
    isError,
  } = useQuery(
    [
      'websites',
      {
        fields: 'id,name',
        search: websiteQueryValue,
      },
    ],
    async () => {
      return await ApiService.get('websites', {
        params: {
          'fields[]': 'id,name',
          search: websiteQueryValue ? websiteQueryValue : '',
        },
      });
    },
    {
      enabled: !!websiteQueryValue,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        setValue('name', data.name);
      },
    },
  );

  const {
    data: markets,
    isFetching: marketsIsFetching,
    isLoading: marketsIsLoading,
    isError: marketsIsError,
  } = useQuery(
    [
      'countries',
      {
        fields: 'id,countryname',
        search: marketQueryValue,
      },
    ],
    async () => {
      return await ApiService.get('countries', {
        params: {
          'fields[]': 'id,countryname',
          search: marketQueryValue ? marketQueryValue : '',
          per_page: 100,
        },
      });
    },
    {
      enabled: !!marketQueryValue,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: (data) => {
        setValue('name', data.countryname);
      },
    },
  );

  const getCategory = (data) => {
    const categories = [
      'campaign',
      'nofollow',
      'image',
      'permalink',
      'organic',
      'insertion',
    ];
    for (const category of categories) {
      if (data[category] === 1) {
        return category;
      }
    }
  };

  const {
    data,
    isFetching: dataIsFetching,
    isLoading: dataIsLoading,
    isError: dataIsError,
  } = useQuery(
    ['links', modelId],
    async () => {
      return await ApiService.get(`links/${modelId}`);
    },
    {
      enabled: !!modelId,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onSuccess: ({ data }) => {
        setValue('referring_site', data.data.referring_site);
        setValue('target_url', data.data.target_url);
        setValue('price', data.data.price);
        setValue('discount_percentage', data.data.discount_percentage);
        setValue('anchor_text', data.data.anchor_text);
        setValue('live_month', data.data.live_date);
        setValue('category', getCategory(data.data));
        setValue('website', data.data.website_name);
        setValue('market', data.data.market_name);
        setWebsiteQuery(data.data.website_name);
        setMarketQuery(data.data.market_name);
        setLiveMonth(DateTimeHelper.getYearAndMonth(data.data.live_date));
      },
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const handleCategoryChange = (e) => {
    setValue('category', e.target.value);
  };

  return (
    <>
      <Modal
        title={modelId ? 'Edit link' : 'Create link'}
        onClose={() => {
          queryClient.invalidateQueries('units');
          onClose();
        }}
        buttons={
          <div className='flex justify-end gap-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                if (activeStep > 0) {
                  return handlePrev();
                }
                onClose();
              }}
              className='linear w-1/2 rounded-xl px-5 py-2.5 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100'
            >
              {activeStep == 0 ? 'Cancel' : 'Back'}
            </button>
            <button
              type='submit'
              onClick={(e) => {
                if (activeStep == 0) {
                  e.preventDefault();
                  return handleNext();
                }
                onSubmit();
              }}
              className='linear w-1/2 rounded-xl bg-indigo-500 px-5 py-2.5 text-base font-medium text-white transition duration-200 hover:bg-indigo-600'
            >
              {activeStep == 1 ? (modelId ? 'Save' : 'Create') : 'Next'}
            </button>
          </div>
        }
      >
        <div className='w-[450px]'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stepper
              className='mb-6'
              activeStep={activeStep}
              isLastStep={(value) => setIsLastStep(value)}
              isFirstStep={(value) => setIsFirstStep(value)}
              activeLineClassName='bg-brand-500'
              lineClassName='bg-gray-100'
            >
              <Step
                completedClassName='!bg-brand-500 text-white border-brand-500'
                activeClassName='border-brand-500 bg-brand-100 text-brand-500'
                onClick={() => setActiveStep(0)}
                className={`cursor-pointer select-none border-4 border-gray-100 bg-gray-100`}
              >
                1
              </Step>
              <Step
                completedClassName='!bg-brand-500 text-white border-brand-500'
                activeClassName='border-brand-500 bg-brand-100 text-brand-500'
                className={`cursor-pointer select-none border-4 border-gray-100 bg-gray-100`}
                onClick={() => setActiveStep(1)}
              >
                2
              </Step>
            </Stepper>
            <div className='mb-[20px] grid grid-cols-1 gap-6'>
              {activeStep === 0 && (
                <>
                  <div>
                    <Input
                      className=''
                      variant='standard'
                      label='Referring site'
                      placeholder='Enter the referring site'
                      color={`${errors.name ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                      {...register('referring_site', {
                        required: true,
                        minLength: 3,
                        maxLength: 700,
                      })}
                    />
                    {errors.referring_site &&
                      errors.referring_site.type === 'required' && (
                        <HelperText text='Referring site is required' />
                      )}
                    {errors.referring_site &&
                      errors.referring_site.type === 'minLength' && (
                        <HelperText text='Referring site must be at least 3 characters' />
                      )}
                  </div>
                  <div>
                    <Input
                      className=''
                      variant='standard'
                      label='Target url'
                      placeholder='Enter the target url'
                      color={`${errors.name ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                      {...register('target_url', {
                        required: true,
                        minLength: 3,
                        maxLength: 700,
                      })}
                    />
                    {errors.target_url &&
                      errors.target_url.type === 'required' && (
                        <HelperText text='Target url is required' />
                      )}
                    {errors.target_url &&
                      errors.target_url.type === 'minLength' && (
                        <HelperText text='Target url must be at least 3 characters' />
                      )}
                  </div>
                  <div className='relative'>
                    <Input
                      className=''
                      variant='standard'
                      label='Website *'
                      placeholder='Enter the website'
                      value={websiteQuery}
                      onChangeCapture={(e) => {
                        setWebsiteQuery(e.target.value);
                      }}
                      color={`${errors.name ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                    />
                    {websites &&
                      websiteQuery !== getValues('website') &&
                      websiteQuery && (
                        <Card className='absolute top-12 z-50 w-full rounded-lg border bg-white shadow-lg'>
                          <div className='max-h-72 overflow-y-auto'>
                            <List className='text-sm text-gray-700'>
                              {websiteQuery &&
                                websites.data.data.map((website) => (
                                  <ListItem
                                    onClick={() => {
                                      setWebsiteQuery(website.name);
                                      setValue('website', website.name);
                                    }}
                                    key={website.id}
                                    className='px-2 py-1.5'
                                  >
                                    {website.name}
                                  </ListItem>
                                ))}
                            </List>
                          </div>
                        </Card>
                      )}
                    {errors.website && errors.website.type === 'required' && (
                      <HelperText text='Website is required' />
                    )}
                  </div>
                  <div className='relative'>
                    <Input
                      className=''
                      variant='standard'
                      label='Market *'
                      value={marketQuery}
                      placeholder='Enter the market'
                      onChangeCapture={(e) => {
                        setMarketQuery(e.target.value);
                      }}
                      color={`${errors.name ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                    />
                    {/* {errors.name && errors.name.type === 'required' && (
                          <HelperText text='Name is required' />
                        )} */}
                    {/* {errors.name && errors.name.type === 'minLength' && (
                      <HelperText text='Name must be at least 3 characters' />
                    )} */}
                    {markets &&
                      marketQuery !== getValues('market') &&
                      marketQuery !== '' && (
                        <Card className='absolute top-12 z-50 w-full rounded-lg border bg-white shadow-lg'>
                          <div className='max-h-72 overflow-y-auto'>
                            {marketQuery && markets.data.data.length > 0 && (
                              <List className='text-sm text-gray-700'>
                                {markets.data.data.map((market) => (
                                  <ListItem
                                    onClick={() => {
                                      setMarketQuery(market.countryname);
                                      setValue('market', market.countryname);
                                    }}
                                    key={market.id}
                                    className='px-2 py-1.5'
                                  >
                                    {market.countryname}
                                  </ListItem>
                                ))}
                              </List>
                            )}
                          </div>
                        </Card>
                      )}
                  </div>
                  <div>
                    <ReactSelect
                      color={`${errors.role ? 'red' : 'indigo'}`}
                      variant='standard'
                      label='Category'
                      value={getValues('category')}
                      onChange={(value) => setValue('category', value)}
                      labelProps={{ className: 'text-gray-700' }}
                    >
                      <Option value='image'>Image</Option>
                      <Option value='nofollow'>Nofollow</Option>
                      <Option value='permalink'>Permalink</Option>
                      <Option value='organic'>Organic</Option>
                      <Option value='insertion'>Insertion</Option>
                      <Option value='campaign'>Campaign</Option>
                    </ReactSelect>
                  </div>
                </>
              )}
              {activeStep == 1 && (
                <>
                  <div>
                    <Input
                      className=''
                      variant='standard'
                      label='Price'
                      type='number'
                      placeholder='Enter the price'
                      color={`${errors.name ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                      {...register('price', {
                        required: true,
                      })}
                    />
                    {errors.name && errors.name.type === 'required' && (
                      <HelperText text='Name is required' />
                    )}
                    {errors.name && errors.name.type === 'minLength' && (
                      <HelperText text='Name must be at least 3 characters' />
                    )}
                  </div>
                  <div>
                    <Input
                      className=''
                      variant='standard'
                      label='Discount percentage'
                      type='number'
                      placeholder='Enter the discount percentage'
                      color={`${errors.name ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                      {...register('discount_percentage', {
                        min: 0,
                        max: 100,
                      })}
                    />
                    {errors.name && errors.name.type === 'required' && (
                      <HelperText text='Name is required' />
                    )}
                    {errors.name && errors.name.type === 'minLength' && (
                      <HelperText text='Name must be at least 3 characters' />
                    )}
                  </div>
                  <div>
                    <Input
                      className=''
                      variant='standard'
                      label='Anchor text'
                      placeholder='Enter the anchor text'
                      color={`${errors.name ? 'red' : 'indigo'}`}
                      labelProps={{ className: 'text-gray-700' }}
                      {...register('anchor_text', {
                        required: false,
                        maxLength: 500,
                      })}
                    />
                    {errors.name && errors.name.type === 'required' && (
                      <HelperText text='Name is required' />
                    )}
                    {errors.name && errors.name.type === 'minLength' && (
                      <HelperText text='Name must be at least 3 characters' />
                    )}
                  </div>
                  <div className=''>
                    <div className='mb-2 text-xs text-gray-700'>Live month</div>
                    <input
                      className='w-full rounded-lg border px-3 py-2 text-sm outline-0'
                      type='month'
                      id='start'
                      name='start'
                      min='2020-01'
                      onChange={(e) => setLiveMonth(e.target.value)}
                      value={liveMonth}
                    />
                  </div>
                  <div>
                    <CurrencySelector
                      onCurrencyChange={handleCurrencyChange}
                      current={selectedCurrency}
                    />
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};
export default LinkEditModal;
