import { Circle } from '@mui/icons-material';
import Dropdown from 'components/dropdown';
import React, { useEffect } from 'react';
import { BsArrowBarUp } from 'react-icons/bs';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { IoNotifications } from 'react-icons/io5';
import { RiNotificationBadgeFill } from 'react-icons/ri';
import { QueryClient, useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import ApiService from 'services/ApiService';

export default function Notifications() {
  const {
    data: notifications,
    isLoading,
    refetch,
  } = useQuery(
    'notifications',
    async () => {
      const res = await ApiService.get('notifications');
      return res.data;
    },
    {
      refetchInterval: 120000,
      refetchOnWindowFocus: true,
    },
  );

  const markAsReadMutation = useMutation(
    async () => {
      const res = await ApiService.post(`notifications/mark-as-read`);
      return res.data;
    },
    {
      onSuccess: () => {
        refetch();
      },
    },
  );

  const getMessage = (type, notification) => {
    switch (type) {
      case 'deal-approved':
        return `Deal ${notification.data.deal_id} has been approved by ${notification.data.approved_by}.`;
      case 'deal-indexed':
        return `Deal ${notification.data.deal_id} has been indexed. It is ready for processing.`;
      case 'deal-deleted':
        return `Deal ${notification.data.deal_id} has been deleted by ${notification.data.deleter}.`;
      default:
        return '';
    }
  };

  const getNotificationLink = (notification) => {
    if (notification.type == 'deal-approved') {
      return `/admin/deals/${notification.data.deal_id}/edit`;
    }
  };

  return (
    <Dropdown
      button={
        <p className='relative cursor-pointer'>
          <IoNotifications className='h-4 w-4 text-gray-600 dark:text-white' />
          {notifications?.data?.length > 0 && (
            <>
              {!isLoading && !notifications?.data[0]?.read_at && (
                <Circle className='absolute -top-0.5 right-0 !text-[8px] text-red-500' />
              )}
            </>
          )}
        </p>
      }
      animation='origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out'
      children={
        <div className='flex w-[360px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]'>
          <div className='flex items-center justify-between'>
            <p className='text-base font-bold text-navy-700 dark:text-white'>
              Notification
            </p>
            <button
              onClick={() => markAsReadMutation.mutate()}
              className='text-sm font-bold text-navy-700 dark:text-white'
            >
              Mark as read
            </button>
          </div>

          {notifications?.data?.map((notification) => (
            <Link
              key={notification.id}
              to={getNotificationLink(notification)}
              className={`flex w-full items-center rounded-lg py-1 ${
                notification.read_at == null
                  ? 'font-bold text-brand-800 dark:bg-navy-800'
                  : 'font-medium text-gray-600 dark:bg-navy-700'
              }`}
            >
              <Circle
                className={`!text-[10px] ${
                  notification.read_at == null
                    ? 'text-brand-500'
                    : 'text-gray-100'
                }`}
              />
              <div className='ml-2 flex h-full w-full flex-col justify-center rounded-lg px-1 text-xs'>
                <p className='flex justify-between gap-2 text-left'>
                  <div>{getMessage(notification.type, notification)}</div>
                  <div className='shrink-0 text-gray-400/80'>
                    {notification.created_at}
                  </div>
                </p>
              </div>
            </Link>
          ))}
        </div>
      }
      classNames={'py-2 top-4 -left-[230px] md:-left-[440px] w-max'}
    />
  );
}
