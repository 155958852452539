import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { IoDocumentOutline } from 'react-icons/io5';
import { MenuItem } from '@material-tailwind/react';
import ApiService from 'services/ApiService';
import { useLocation } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';

const CsvExportButton = ({
  endpoint = '/links/export',
  additionalParams = {},
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // access query parameters
  const searchQuery = queryParams.get('q');

  const fetchCsv = async () => {
    const response = await ApiService.get(
      process.env.REACT_APP_API_URL + endpoint,
      {
        responseType: 'blob',
        params: { search: searchQuery, ...additionalParams },
      },
    );
    return response.data;
  };

  const { refetch, isFetching } = useQuery('downloadCsv', fetchCsv, {
    enabled: false,
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      // remove first slash from the endpoint
      const fileName = endpoint.replace(/^\//, '') + '.csv';
      link.setAttribute('download', fileName); // or any other file name
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    },
    onError: (error) => {
      console.error('Error downloading the CSV file', error);
    },
  });

  return (
    <MenuItem
      onClick={() => refetch()}
      className='flex flex-row items-center gap-2 hover:bg-gray-100/50'
    >
      {isFetching ? (
        <FaSpinner className='animate-spin' />
      ) : (
        <IoDocumentOutline />
      )}
      <span>Export to CSV</span>
    </MenuItem>
  );
};

export default CsvExportButton;
