import Checkbox from 'components/checkbox';
import InputField from 'components/fields/InputField';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FcGoogle } from 'react-icons/fc';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthService from 'services/AuthService';

export default function SignIn() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);
  const login = useStoreActions((actions) => actions.auth.login);
  const mutation = useMutation(
    (credentials) => AuthService.login(credentials.email, credentials.password),
    {
      onError: (error) => {
        if (error?.response?.data?.message == 'Invalid credentials') {
          toast.error('Invalid credentials, please try again!', {
            position: 'bottom-center',
          });
        }
      },
      onSuccess: ({ user, access_token, permissions }) => {
        login({
          user,
          token: access_token,
          permissions,
        });
      },
    },
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/admin');
    }
  }, [isAuthenticated]);

  const onSubmit = async (data) => {
    const res = await mutation.mutate(data, {
      onSuccess: () => {
        navigate('/admin');
      },
    });
  };

  const getPasswordErrors = () => {
    if (errors?.password?.type == 'required') {
      return 'Password is required.';
    }
    if (errors?.password?.type == 'minLength') {
      return 'Password must be at least 6 characters.';
    }
  };

  const getEmailErrors = () => {
    if (errors?.email?.type == 'required') {
      return 'Email is required.';
    }
    if (errors?.email?.type == 'pattern') {
      return 'Email is not valid.';
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start'
    >
      {/* Sign in section */}
      <div className='mt-[3vh] flex w-full max-w-full flex-col items-start gap-2 md:pl-4 lg:pl-0 xl:max-w-[420px]'>
        <h4 className='mb-2.5 text-4xl font-bold text-navy-700 dark:text-white'>
          Sign In
        </h4>
        <p className='mb-6 ml-1 text-base text-gray-600'>
          Enter your email and password to sign in!
        </p>
        <div className='flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800'>
          <div className='rounded-full text-xl'>
            <FcGoogle />
          </div>
          <h5 className='text-sm font-medium text-navy-700 dark:text-white'>
            Sign In with Google
          </h5>
        </div>
        <div className='flex w-full  items-center gap-3'>
          <div className='h-px w-full bg-gray-200 dark:bg-navy-700' />
          <p className='text-base text-gray-600 dark:text-white'> or </p>
          <div className='h-px w-full bg-gray-200 dark:bg-navy-700' />
        </div>
        {/* Email */}
        <Controller
          name='email'
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputField
              variant='auth'
              extra='w-full'
              label='Email*'
              placeholder='mail@simmmple.com'
              id='email'
              type='text'
              onInputChange={onChange}
              {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
            />
          )}
        />
        <div className='ml-2 min-h-[20px] w-full text-sm font-medium text-red-500'>
          {getEmailErrors()}
        </div>

        {/* Password */}
        <Controller
          name='password'
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputField
              variant='auth'
              extra='w-full'
              label='Password*'
              placeholder='Enter password...'
              id='password'
              type='password'
              onInputChange={onChange}
              {...register('password', {
                required: true,
                minLength: 6,
                maxLength: 50,
              })}
            />
          )}
        />
        <div className='ml-2 min-h-[20px] w-full text-sm font-medium text-red-500'>
          {getPasswordErrors()}
        </div>
        <div className='mb-4 flex w-full items-center justify-between px-2'>
          <div className='flex items-center'>
            <Checkbox />
            <p className='ml-2 text-sm font-medium text-navy-700 dark:text-white'>
              Keep me logged In
            </p>
          </div>
          <Link
            className='text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white'
            to='/auth/forgot-password'
          >
            Forgot Password?
          </Link>
        </div>
        {errorMessage && (
          <div className='mb-2 text-sm font-medium text-red-500'>
            {errorMessage}
          </div>
        )}
        <button
          type='submit'
          className='linear w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'
        >
          {mutation.isLoading ? 'Signing in...' : 'Sign In'}
        </button>
        <div className=''>
          <span className=' text-sm font-medium text-navy-700 dark:text-gray-600'>
            Not registered yet?
          </span>
          <a
            href=' '
            className='ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white'
          >
            Create an account
          </a>
        </div>
      </div>
    </form>
  );
}
