import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from '@material-tailwind/react';
import React from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { FiMoreHorizontal } from 'react-icons/fi';
import { MdDeselect, MdSelectAll } from 'react-icons/md';

export default function DataTableDropdown({
  onDeleteSelected,
  onSelectAll,
  onDeselectAll = null,
  children = null,
  showDefaultOptions = true,
}) {
  return (
    <Menu
      placement='bottom-start'
      dismiss={{
        itemPress: true,
        outsidePress: true,
      }}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0.8, y: -10 },
      }}
    >
      <MenuHandler>
        <button className='rounded-lg bg-lightPrimary px-3 py-2 transition hover:bg-gray-100'>
          <FiMoreHorizontal className='text-xl text-gray-700' />
        </button>
      </MenuHandler>
      <MenuList className='font-medium text-gray-700'>
        {children}
        {showDefaultOptions && (
          <>
            <MenuItem
              onClick={onSelectAll}
              className='flex flex-row items-center gap-2 hover:bg-gray-100/50'
            >
              <MdSelectAll className='text-lg' /> <span>Select all</span>
            </MenuItem>
            <MenuItem
              onClick={onDeselectAll}
              className='flex flex-row items-center gap-2 hover:bg-gray-100/50'
            >
              <MdDeselect className='text-lg' /> <span>Deselect all</span>
            </MenuItem>
          </>
        )}
        {showDefaultOptions && (
          <MenuItem
            onClick={onDeleteSelected}
            className='flex flex-row items-center gap-2 text-red-500 hover:bg-red-100/50'
          >
            <AiOutlineDelete className='text-lg' /> <span>Delete selected</span>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}
