import React, { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import GlobalSearchItem from './GlobalSearchItem';
import { IoClose, IoCloseCircle } from 'react-icons/io5';
import { useDebounce } from 'use-debounce';
import { useQuery } from 'react-query';
import ApiService from 'services/ApiService';
import { AnimatePresence, m } from 'framer-motion';
import ClickAwayListener from 'react-click-away-listener';

const GlobalSearchInput = () => {
  const [query, setQuery] = useState('');
  const [queryValue] = useDebounce(query, 400);
  const [results, setResults] = useState([]);

  useQuery(
    [
      'globalSearch',
      {
        query: queryValue,
      },
    ],
    async () => {
      const res = await ApiService.get('search', {
        params: { query: queryValue },
      });
      setResults(res.data);
      return res.data;
    },
    {
      enabled: queryValue.length >= 3,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const clearSearch = () => {
    setQuery('');
    setResults([]);
  };

  useEffect(() => {
    if (queryValue.length < 3) {
      setResults([]);
    }
  }, [queryValue]);

  return (
    <ClickAwayListener onClickAway={clearSearch}>
      <div className='relative h-full'>
        <div className='flex h-full items-center justify-between rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]'>
          <div className='flex h-full items-center'>
            <p className='pl-3 pr-2 text-xl'>
              <FiSearch className='h-4 w-4 text-gray-400 dark:text-white' />
            </p>
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              type='text'
              placeholder='Search...'
              className='block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit'
            />
          </div>
          <AnimatePresence>
            {query && query.length >= 3 && (
              <m.button
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'spring', bounce: 0.4, duration: 0.6 }}
                onClick={() => clearSearch()}
                className='shrink-0 pr-2 text-gray-500 transition hover:text-indigo-500'
              >
                <IoCloseCircle className='text-lg' />
              </m.button>
            )}
          </AnimatePresence>
        </div>
        <AnimatePresence mode='wait'>
          {results.length > 0 && (
            <m.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 40 }}
              transition={{ type: 'spring', bounce: 0.4, duration: 0.6 }}
              className='absolute top-16 w-[350px] overflow-hidden rounded-xl border bg-white shadow-lg'
            >
              <div className='flex max-h-[400px] flex-col overflow-y-auto'>
                {results.map((result) => (
                  <GlobalSearchItem
                    key={result.id}
                    onClick={() => {
                      setQuery('');
                      setResults([]);
                    }}
                    model={result.type}
                    title={result.title}
                    url={result.url}
                  />
                ))}
              </div>
            </m.div>
          )}
        </AnimatePresence>
      </div>
    </ClickAwayListener>
  );
};

export default GlobalSearchInput;
