/* eslint-disable */

import { HiX } from 'react-icons/hi';
import Links from './components/Links';

import routes from 'routes.js';
import logo from 'assets/img/logos/logo.webp';
import darkLogo from 'assets/img/logos/logo-dark.webp';
import { useStoreState } from 'easy-peasy';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from '@material-tailwind/react';
import { MdArrowDownward } from 'react-icons/md';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ open, onClose }) => {
  const theme = useStoreState((state) => state.theme);
  return (
    <div
      className={`sm:none linear fixed !z-50 flex min-h-full w-[235px] flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all duration-500 dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? 'opacity-1 translate-x-0' : '-translate-x-96 opacity-0'
      }`}
    >
      <span
        className='absolute right-4 top-4 block cursor-pointer xl:hidden'
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[26px] mt-[40px] flex items-center`}>
        <Link
          to='/admin/default'
          className='font-poppins ml-1 mt-1 h-2.5 text-[26px] font-bold uppercase text-navy-700 dark:text-white'
        >
          <img
            src={theme === 'dark' ? darkLogo : logo}
            alt='logo'
            className='w-32'
          />
        </Link>
      </div>
      <div className='mb-7 mt-[58px] h-px bg-gray-300 dark:bg-white/30' />
      {/* Nav item */}

      <ul className='mb-auto pt-1'>
        <Links routes={routes} />
      </ul>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
