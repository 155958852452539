import Footer from 'components/footer/Footer';
import Navbar from 'components/navbar';
import Sidebar from 'components/sidebar';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { hasPermissionTo } from 'helpers/AuthHelper';
import React from 'react';
import { useQuery } from 'react-query';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import routes from 'routes.js';
import ApiService from 'services/ApiService';

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState('Main Dashboard');
  const auth = useStoreState((state) => state.auth.isAuthenticated);

  const setPermissions = useStoreActions(
    (actions) => actions.auth.setPermissions,
  );

  const { data: permissions, isLoading } = useQuery('permissions', async () => {
    const data = await ApiService.get('/permissions');
    setPermissions(data.data);
  });

  React.useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true),
    );
  }, []);

  React.useEffect(() => {
    getActiveRoute(routes);
    // window.scroll(0, 0);
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = 'Main Dashboard';
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + '/' + routes[i].path,
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (!hasPermissionTo(prop.permission)) return null;
      if (prop.layout === '/admin') {
        return (
          <Route
            path={`/${prop.path}`}
            element={auth ? prop.component : <Navigate to='/auth/sign-in' />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  if (isLoading) {
    return null;
  }

  document.documentElement.dir = 'ltr';
  return (
    <div className='flex h-full w-full'>
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className='h-full w-full bg-lightPrimary dark:!bg-navy-900'>
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 ${
            open && 'xl:ml-[254px]'
          }`}
        >
          {/* Routes */}
          <div className='h-full'>
            <Navbar
              onOpenSidenav={() => setOpen((open) => !open)}
              logoText={'Horizon UI Tailwind React'}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className='pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2'>
              <Routes>
                {getRoutes(routes)}

                <Route
                  path='/'
                  element={<Navigate to='/admin/default' replace />}
                />
              </Routes>
            </div>
            <div className='p-3'>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
