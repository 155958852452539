import { Zoom } from 'react-toastify';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from './tailwind.config';
const twConfig = resolveConfig(tailwindConfig);

export const defaultToastOptions = {
  position: 'bottom-right',
  bodyClassName: 'font-dm',
  transition: Zoom,
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  closeButton: true,
  isLoading: false,
  theme: 'colored',
};

export const defaultQueryClientOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      retry: 2,
      keepPreviousData: true,
      staleTime: 120000,
      cacheTime: 122000,
    },
  },
};

export const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderWidth: `0 0 ${state.isFocused ? '2px' : '1px'} 0`,
    borderRadius: '0',
    borderColor: state.isFocused ? twConfig.theme.colors.brand[500] : '#E5E7EB',
    boxShadow: 'none',
    '&:hover': {
      borderColor: state.isFocused
        ? twConfig.theme.colors.brand[500]
        : '#E5E7EB',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#9CA3AF',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#4B5563',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: twConfig.theme.colors.brand[100],
    borderRadius: '6px',
    color: '#4B5563',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#4B5563',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#4B5563',
    borderRadius: '6px',
    ':hover': {
      backgroundColor: twConfig.theme.colors.indigo[200],
      color: '#ef4444',
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '10px',
    overflow: 'hidden',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? twConfig.theme.colors.brand[500]
      : '#FFFFFF',
    color: state.isSelected ? '#FFFFFF' : '#4B5563',
    ':hover': {
      backgroundColor: twConfig.theme.colors.brand[200],
    },
  }),
};
