import React from 'react';
import { MdAdd } from 'react-icons/md';

const CreateButton = ({ onClick, className }) => {
  return (
    <button
      onClick={onClick}
      className={`h-full rounded-lg bg-green-500 p-2 text-white shadow transition hover:cursor-pointer hover:bg-green-600${
        className || ''
      }`}
    >
      <MdAdd className='text-xl' />
    </button>
  );
};

export default CreateButton;
