import ApiService from './ApiService';

const PaymentMethodService = {
  getAll: async (page = 1, query = '') => {
    const response = await ApiService.get(
      `/payment-methods?search=${query}&page=${page}`,
    );
    return response.data;
  },
  bulkDelete: async (ids) => {
    const response = await ApiService.post('payment-methods/bulk-delete', {
      ids,
    });
    return response.data;
  },
  delete: async (id) => {
    const response = await ApiService.delete('payment-methods/' + id);
    return response.data;
  },
  get: async (id) => {
    const response = await ApiService.get('payment-methods/' + id);
    return response.data;
  },
  create: async (data) => {
    const response = await ApiService.post('payment-methods', data);
    return response.data;
  },
  update: async (id, data) => {
    const response = await ApiService.put('payment-methods/' + id, data);
    return response.data;
  },
};

export default PaymentMethodService;
