import React from 'react';

import Card from 'components/card';
import { MdClose } from 'react-icons/md';
import Modal from 'components/modals/Modal';

const PreviewCsvData = ({ data = [] }) => {
  const getTableHeaders = (headers) => {
    return headers.map((header) => {
      return <th className='pl-2 capitalize'>{header.replace('_', ' ')}</th>;
    });
  };
  return (
    <>
      <Modal title='Preview CSV' buttons={null}>
        <div className='max-h-96 overflow-auto opacity-70'>
          <table className='table table-auto text-xs'>
            <tr>{getTableHeaders(Object.keys(data[0]))}</tr>
            {data.map((item) => (
              <tr className='border-b'>
                <td className='px-2 py-1.5 odd:bg-gray-50'>
                  {item.referring_site}
                </td>
                <td className='px-2 py-1.5 odd:bg-gray-50'>
                  {item.target_url}
                </td>
                <td className='px-2 py-1.5 odd:bg-gray-50'>
                  {item.anchor_text}
                </td>
                <td className='px-2 py-1.5 odd:bg-gray-50'>{item.price}</td>
                <td className='px-2 py-1.5 odd:bg-gray-50'>
                  {item.discount_percentage}
                </td>
                <td className='px-2 py-1.5 odd:bg-gray-50'>{item.currency}</td>
              </tr>
            ))}
          </table>
        </div>
      </Modal>
    </>
  );
};
export default PreviewCsvData;
