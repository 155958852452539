import { Zoom, toast } from 'react-toastify';
import { defaultToastOptions } from 'options';

const ErrorHelper = {
  // Function to handle API response status codes
  // api.js

  // Function to handle API response status codes
  handleApiError: (statusCode, loadingToastId = null) => {
    let errorMessage = '';

    switch (statusCode) {
      case 400:
        errorMessage = 'Oops! There was a problem with your request.';
        break;
      case 401:
        errorMessage = 'Sorry, you are not authorized to do that.';
        break;
      case 403:
        errorMessage = 'Oops! You are not allowed to do that.';
        break;
      case 404:
        errorMessage = "Sorry, we couldn't find what you're looking for.";
        break;
      case 409:
        errorMessage = 'Sorry, there was a conflict with your request.';
        break;
      case 422:
        errorMessage =
          "Sorry, we couldn't process your request. Check if the data you entered is valid.";
        break;
      case 500:
        errorMessage = 'Oops! Something went wrong. Please try again later.';
        break;
      case 503:
        errorMessage =
          'Oops! Our service is temporarily unavailable. Please try again later.';
        break;
      default:
        errorMessage = 'Oops! Something went wrong. Please try again later.';
    }

    toast.update(loadingToastId, {
      render: (
        <div style={{ whiteSpace: 'pre-line' }} className='font-dm text-xs'>
          {<span dangerouslySetInnerHTML={{ __html: errorMessage }} />}
        </div>
      ),
      type: 'error',
      ...defaultToastOptions,
    });
    return errorMessage;
  },
  handleValidationErrors: (errors, loadingToastId) => {
    // let errorMessage = 'Validation Error: ';
    let errorMessage = '';

    // Loop through each field with validation errors
    for (const field in errors) {
      if (errors.hasOwnProperty(field)) {
        const fieldErrors = errors[field];
        errorMessage += `${fieldErrors.join(', ')}\n`; // Add a new line after each error
      }
    }
    return errorMessage;

    toast.update(loadingToastId, {
      render: (
        <div style={{ whiteSpace: 'pre-line' }} className='font-dm text-xs'>
          {<span dangerouslySetInnerHTML={{ __html: errorMessage }} />}
        </div>
      ),
      type: 'error',
      autoClose: false,
      isLoading: false,
      closeOnClick: true,
      closeButton: true,
      animation: Zoom,
    });

    return errorMessage;
  },
};

export default ErrorHelper;
