import { FaSpinner } from 'react-icons/fa';

export default ({ className }) => {
  return (
    // center fixed spinner
    <div className='fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform'>
      <div className='rounded-full border bg-white p-3 shadow-lg dark:border-gray-800 dark:bg-gray-800'>
        <FaSpinner className='animate-spin text-3xl text-gray-500 xl:text-5xl' />
      </div>
    </div>
  );
};
