import Dropdown from 'components/dropdown';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useEffect } from 'react';
import { FiAlignJustify, FiSearch } from 'react-icons/fi';
import { RiMoonFill, RiSunFill } from 'react-icons/ri';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import AuthService from 'services/AuthService';
import Notifications from './components/Notifications';
import GlobalSearchInput from './components/GlobalSearchInput';

const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;

  const user = useStoreState((state) => state.auth.user);
  const logout = useStoreActions((actions) => actions.auth.logout);
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const theme = useStoreState((state) => state.theme);
  const toggleTheme = useStoreActions((actions) => actions.toggleTheme);

  const logoutMutation = useMutation(() => AuthService.logout(), {
    onSuccess: async () => {
      await logout();
      navigate('/auth/sign-in');
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth/sign-in');
    }
  }, [isAuthenticated]);

  return (
    <nav className='sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl p-2 backdrop-blur-xl dark:bg-[#0b14374d]'>
      <div className='ml-[6px] select-none'>
        <p className='shrink text-3xl capitalize text-navy-700 dark:text-white'>
          <Link
            to='#'
            className='font-bold capitalize hover:text-navy-700 dark:hover:text-white'
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className='relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2'>
        <GlobalSearchInput />
        <span
          className='flex cursor-pointer text-xl text-gray-600 dark:text-white'
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className='h-5 w-5' />
        </span>
        {/* start Notification */}
        <Notifications />
        <div
          className='cursor-pointer text-gray-600'
          onClick={() => toggleTheme()}
        >
          {theme == 'dark' ? (
            <RiSunFill className='h-4 w-4 text-gray-600 dark:text-white' />
          ) : (
            <RiMoonFill className='h-4 w-4 text-gray-600 dark:text-white' />
          )}
        </div>
        {/* Profile & Dropdown */}
        <Dropdown
          button={
            <button className='flex h-10 w-10 cursor-pointer select-none items-center justify-center rounded-full bg-brand-500 text-white'>
              {user?.name?.substring(0, 1)}
            </button>
          }
          children={
            <div className='flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none'>
              <div className='p-4'>
                <div className='flex items-center gap-2'>
                  <p className='text-sm font-bold text-navy-700 dark:text-white'>
                    👋 Hey, {user?.name}
                  </p>{' '}
                </div>
              </div>
              <div className='h-px w-full bg-gray-200 dark:bg-white/20 ' />

              <div className='flex flex-col gap-2 p-4'>
                <Link
                  to='/admin/profile'
                  className='text-left text-sm font-medium text-gray-700'
                >
                  Profile
                </Link>
                <button
                  onClick={logoutMutation.mutate}
                  className='text-left text-sm font-medium text-red-500 hover:text-red-500'
                >
                  {logoutMutation.isLoading ? 'Logging out...' : 'Log Out'}
                </button>
              </div>
            </div>
          }
          classNames={'py-2 top-8 -left-[180px] w-max'}
        />
      </div>
    </nav>
  );
};

export default Navbar;
