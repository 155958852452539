import ApiService from './ApiService';

const SupplierService = {
  getAll: async (page = 1, query = '') => {
    const response = await ApiService.get(
      `/clients?search=${query}&page=${page}`,
    );
    return response.data;
  },
  bulkDelete: async (ids) => {
    const response = await ApiService.post('clients/bulk-delete', {
      ids,
    });
    return response.data;
  },
  delete: async (id) => {
    const response = await ApiService.delete('clients/' + id);
    return response.data;
  },
};

export default SupplierService;
