/* eslint-disable */
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import DashIcon from 'components/icons/DashIcon';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from '@material-tailwind/react';
import { useState } from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { FaChevronDown, FaDatabase } from 'react-icons/fa';
import { FiChevronsDown } from 'react-icons/fi';
import { hasPermissionTo } from 'helpers/AuthHelper';
// chakra imports

export function SidebarLinks(props) {
  // Chakra color mode
  let location = useLocation();

  const { routes } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const [showMore, setShowMore] = useState(false);
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const createLinks = (routes) => {
    const routesToShow = routes
      .filter((route) => !route.hideFromSidebar)
      .filter((route) => hasPermissionTo(route.permission))
      .slice(0, 8);
    const dropdownRoutes = routes
      .filter((route) => !route.hideFromSidebar)
      .filter((route) => hasPermissionTo(route.permission))
      .slice(8, routes.length);
    return (
      <>
        {routesToShow.map((route, index) => {
          if (
            route.layout === '/admin' ||
            route.layout === '/auth' ||
            route.layout === '/rtl'
          ) {
            if (!hasPermissionTo(route?.permission)) return null;
            return (
              <Link
                key={index}
                to={route.layout + '/' + route.path}
                className='select-none'
              >
                <div className='relative mb-3 flex hover:cursor-pointer'>
                  <li className='my-[3px] flex cursor-pointer items-center px-6'>
                    <span
                      className={`${
                        activeRoute(route.path) === true
                          ? 'font-bold text-brand-500 dark:text-white'
                          : 'font-medium text-gray-600'
                      }`}
                    >
                      {route.icon ? route.icon : <DashIcon />}{' '}
                    </span>
                    <p
                      className={`leading-1 ml-4 flex ${
                        activeRoute(route.path) === true
                          ? 'font-bold text-navy-700 dark:text-white'
                          : 'font-medium text-gray-600'
                      }`}
                    >
                      {route.name}
                    </p>
                  </li>
                  {activeRoute(route.path) ? (
                    <div className='absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400' />
                  ) : null}
                </div>
              </Link>
            );
          }
        })}
        {dropdownRoutes.length > 0 && (
          <Menu open={showMore} handler={handleShowMore} placement='right'>
            <MenuHandler className={`p-3`} onClick={() => handleShowMore()}>
              <div>
                <div
                  className={`flex w-full cursor-pointer flex-row items-center justify-between rounded-lg px-3 py-2 text-gray-700 transition ${
                    showMore
                      ? 'bg-brand-100 text-brand-800 ring-2 ring-brand-100 dark:bg-brand-900 dark:ring-brand-900'
                      : ' bg-lightPrimary dark:bg-gray-900'
                  }`}
                >
                  <span className='select-none font-dm font-medium'>
                    Data Entries
                  </span>
                  <FaChevronDown
                    className={`text-xs text-gray-700 transition ${
                      showMore ? '-rotate-90' : ''
                    }`}
                  />
                </div>
              </div>
            </MenuHandler>
            <MenuList>
              {dropdownRoutes.map((route, index) => {
                return (
                  <MenuItem key={index}>
                    <Link to={route.layout + '/' + route.path}>
                      <p
                        className={`flex items-center gap-4 font-dm ${
                          activeRoute(route.path) === true
                            ? 'font-bold text-brand-500 dark:text-white'
                            : 'font-medium text-gray-600'
                        }`}
                      >
                        <span>{route.icon ? route.icon : <DashIcon />} </span>
                        <span
                          className={`leading-1 flex ${
                            activeRoute(route.path) === true
                              ? 'font-bold text-navy-700 dark:text-white'
                              : 'font-medium text-gray-600'
                          }`}
                        >
                          {route.name}
                        </span>
                      </p>
                    </Link>
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        )}
      </>
    );
  };
  // BRAND
  return createLinks(routes);
}

export default SidebarLinks;
