import Card from 'components/card';
import ReactApexChart from 'react-apexcharts';
import resolveConfig from 'tailwindcss/resolveConfig';
import { totalSpentChartOptions } from 'variables/charts';
import tailwindConfig from '../../../../tailwind.config';
const twConfig = resolveConfig(tailwindConfig);

const TotalSpent = ({ data }) => {
  const chartData = [
    {
      name: 'Final Price',
      data: data.map((item) => item.value),
      color: twConfig.theme.colors.brand[500],
    },
    {
      name: 'Discount',
      data: data.map((item) => item.discount),
      color: twConfig.theme.colors.lime[500],
    },
  ];

  const calculateTotalSpent = (data) => {
    let totalSpent = 0;
    data.forEach((item) => {
      totalSpent += item.value;
    });
    return totalSpent;
  };

  // Clone the chart options to avoid modifying the original object
  const chartOptions = { ...totalSpentChartOptions };
  chartOptions.xaxis.categories = data.map((item) =>
    new Date(item.month).getTime(),
  );

  // Set the tooltip.y.formatter function
  chartOptions.tooltip.y.formatter = function (val) {
    return '€' + formatNumber(val);
  };

  function formatNumber(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  return (
    <Card extra='text-center overflow-hidden p-[20px]'>
      <div className='flex flex-row justify-between'>
        <div className='mt-3 flex flex-col text-left'>
          <span className='text-xl font-bold text-navy-700 dark:text-white'>
            Total spent
          </span>
          <div className='text-sm font-medium text-gray-600'>
            <span>By Month</span>
          </div>
        </div>
      </div>

      <div className='flex h-full w-full flex-col justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden'>
        <div className='h-[300px] w-full'>
          <ReactApexChart
            options={chartOptions}
            type='area'
            width='100%'
            height='100%'
            series={chartData}
          />
        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;
