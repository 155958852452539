import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import Card from 'components/card';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import ApiService from 'services/ApiService';

export default function ViewDealsModal({
  websiteId = null,
  onClose = null,
  startMonth = null,
  endMonth = null,
}) {
  const { data, isLoading, isError } = useQuery(
    [
      'websites',
      websiteId,
      'deals',
      {
        startMonth,
        endMonth,
      },
    ],
    async () => {
      // add start and end month if they exist
      const request = `websites/${websiteId}/deals`;
      if (startMonth || endMonth) {
        return await ApiService.get(request, {
          params: {
            startMonth,
            endMonth,
          },
        });
      }
      return await ApiService.get(`websites/${websiteId}/deals`);
    },
    {
      enabled: websiteId != null,
    },
  );
  if (isLoading) {
    return null;
  }
  return (
    <Modal isOpen={websiteId} onClose={onClose} className='!z-[1010]'>
      <ModalOverlay className='z-40 bg-gray-800/70 backdrop-blur-sm backdrop-brightness-50' />
      <ModalContent className='!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]'>
        <ModalBody>
          <Card extra='px-[30px] pt-[35px] pb-[40px] min-w-[450px] flex flex-col gap-4 !z-[1004] min-h-[250px] justify-between'>
            <div>
              <h1 className='mb-[20px] text-2xl font-bold'>
                Deals for website: {websiteId}
              </h1>
              <div className='grid grid-cols-5 gap-2 text-sm'>
                {data?.data?.map((item) => (
                  <Link
                    to={`/admin/deals/${item}/edit`}
                    className='rounded rounded-lg bg-brand-100 px-3 py-2 text-brand-800 transition hover:bg-brand-200'
                    key={item}
                  >
                    Deal {item}
                  </Link>
                ))}
              </div>
            </div>

            <div className='flex justify-end gap-2'>
              <button
                onClick={onClose}
                className='linear w-40 rounded-xl bg-lightPrimary px-5 py-2.5 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100'
              >
                Close
              </button>
            </div>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
