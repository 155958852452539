import { useStoreActions } from 'easy-peasy';
import Modal from './Modal';

const BulkImportErrorModal = ({ message = null }) => {
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  return (
    <>
      <Modal
        title='Errors in bulk import'
        buttons={
          <div className='flex justify-end gap-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
              className='linear w-1/2 rounded-xl bg-gray-100 px-5 py-2.5 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-200'
            >
              Close
            </button>
          </div>
        }
      >
        <div className='max-h-[50vh] overflow-y-auto'>
          <div className='mb-[20px] text-sm font-medium text-red-500'>
            {message
              .replace(', ', '')
              .split('\n')
              .map((line, index) => (
                <p className='mt-2' key={index}>
                  {line}
                </p>
              ))}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default BulkImportErrorModal;
