import React from 'react';
import { FaHandshake, FaUser } from 'react-icons/fa';
import { IoDocument } from 'react-icons/io5';
import { MdLink, MdPeople } from 'react-icons/md';
import { Link } from 'react-router-dom';

export default function GlobalSearchItem({
  title = null,
  model = null,
  url = null,
  onClick = null,
}) {
  const getIcon = () => {
    switch (model) {
      case 'user':
        return <MdPeople />;
      case 'deal':
        return <FaHandshake />;
      case 'link':
        return <MdLink />;
      default:
        return null;
    }
  };

  return (
    <Link
      onClick={onClick}
      to={url}
      className='group flex items-center justify-between px-4 py-3 hover:bg-indigo-500 hover:text-white'
    >
      <div className='flex w-full items-center gap-3 overflow-hidden group-hover:text-white'>
        <span className='text-lg text-gray-700 group-hover:text-white'>
          {getIcon()}
        </span>
        <div className='truncate text-xs'>{title}</div>
      </div>
      <div className='ml-2 shrink-0 text-[10px] capitalize text-gray-700 group-hover:text-white'>
        {model}
      </div>
    </Link>
  );
}
