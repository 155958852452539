import DataTable from 'components/table/DataTable';
import { hasPermissionTo } from 'helpers/AuthHelper';
import DateTimeHelper from 'helpers/DateTimeHelper';
import { useState } from 'react';
import CreateForm from './components/CreateForm';
import EditModal from './components/EditModal';

const PaymentMethods = () => {
  const [showCreateForm, setShowCreateForm] = useState(false);

  const customCellRenderer = (data, header, modelId) => {
    if (header === 'created at') {
      return <div>{DateTimeHelper.formatDate(data)}</div>;
    }
    if (header === 'ACTIVE') {
      return (
        <div
          className={`flex ${
            data ? 'bg-green-500' : 'bg-red-500'
          } aspect-square w-2.5 rounded-full text-white`}
        ></div>
      );
    }
    return null;
  };

  return (
    <div>
      <CreateForm
        isOpen={showCreateForm}
        onClose={() => setShowCreateForm(false)}
      />
      <div className='mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6'>
        <div className={`col-span-6 transition`}>
          <DataTable
            columnsData={[
              {
                Header: 'id',
                accessor: 'id',
              },
              {
                Header: 'name',
                accessor: 'name',
              },
              {
                Header: 'created at',
                accessor: 'created_at',
              },
              {
                Header: 'actions',
              },
            ]}
            customCellRenderer={customCellRenderer}
            modelName='payment method'
            request='payment-methods'
            EditModal={EditModal}
            editable
            deletable
            onCreateButtonClick={
              hasPermissionTo('create payment methods') &&
              (() => setShowCreateForm(true))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
