import { action, createStore, debug, persist } from 'easy-peasy';
import { create } from 'zustand';

const store = (set, get) => ({
  theme: 'light',
  setTheme: (theme) => set({ theme }),
  auth: {
    user: null,
    token: null,
    isAuthenticated: false,
    login: (user, token) => {},
    logout: () => {
      set((state) => ({
        auth: {
          ...state.auth,
          user: null,
          token: null,
          isAuthenticated: false,
        },
      }));
    },
    permissions: [],
  },
});

const useStore = create(store, {
  name: 'store',
  partialize: (state) => ({
    auth: {
      user: state.auth.user,
      token: state.auth.token,
      isAuthenticated: state.auth.isAuthenticated,
    },
    theme: state.theme,
  }),
});

export const easyPeasyStore = createStore(
  persist(
    {
      theme: 'light',
      chartView: 'simple',
      toggleTheme: action((state) => {
        state.theme = state.theme === 'dark' ? 'light' : 'dark';
      }),
      modal: {
        name: null,
        props: {},
        openModal: action((state, payload) => {
          state.name = payload.name;
          state.props = payload.props;
        }),
        closeModal: action((state) => {
          state.name = null;
          state.props = {};
        }),
      },
      auth: {
        user: null,
        token: null,
        isAuthenticated: false,
        permissions: [],
        login: action((state, payload) => {
          const { user, token, permissions } = payload;
          state.user = user;
          state.token = token;
          state.isAuthenticated = true;
          state.permissions = permissions;
        }),
        logout: action((state) => {
          state.user = null;
          state.token = null;
          state.isAuthenticated = false;
          state.permissions = [];
        }),
        setPermissions: action((state, payload) => {
          state.permissions = payload;
        }),
      },
      createDealFormData: {
        supplier: null,
        company: null,
        notes: null,
        sheet_url: null,
        invoice_due: null,
        category: null,
        live_month: null,
        link_files: [],
        invoice_files: [],
        updateData: action((state, payload) => {
          state.supplier = payload.supplier;
          state.company = payload.company;
          state.notes = payload.notes;
          state.sheet_url = payload.sheet_url;
          state.invoice_due = payload.invoice_due;
          state.category = payload.category;
          state.live_month = payload.live_month;
        }),
        updateFormFiles: action((state, { link_files, invoice_files }) => {
          state.link_files = link_files;
          state.invoice_files = invoice_files;
        }),
      },
    },
    {
      name: 'store',
      partialize: (state) => ({
        theme: state.theme,
      }),
      storage: 'localStorage',
      allow: ['auth', 'theme', 'chartView'],
    },
  ),
);

export default useStore;
