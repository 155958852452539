import banner from 'assets/img/profile/banner.png';
import Card from 'components/card';
import { useStoreState } from 'easy-peasy';
import NumberHelper from 'helpers/NumberHelper';

const Banner = ({ data }) => {
  const { name, profile_photo_url } = useStoreState((state) => state.auth.user);
  return (
    <Card extra={'items-center w-full h-full p-[16px] bg-cover'}>
      {/* Background and profile */}
      <div
        className='relative mt-1 flex h-32 h-48 w-full justify-center rounded-xl bg-cover'
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className='absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700'>
          <div className='flex h-full w-full cursor-pointer select-none items-center justify-center rounded-full bg-brand-500 text-3xl text-white'>
            {name?.substring(0, 1)}
          </div>
        </div>
      </div>

      {/* Name and position */}
      <div className='mt-16 flex flex-col items-center'>
        <h4 className='text-xl font-bold text-navy-700 dark:text-white'>
          {name}
        </h4>
        <p className='text-base font-normal capitalize text-gray-600'>
          {data.role}
        </p>
      </div>

      {/* Post followers */}
      <div className='mb-3 mt-6 flex gap-4 2xl:gap-8'>
        <div className='flex flex-col items-center justify-center'>
          <p className='text-2xl font-bold text-navy-700 dark:text-white'>
            {data.deal_count}
          </p>
          <p className='text-sm font-normal text-gray-600'>Deals created</p>
        </div>
        <div className='flex flex-col items-center justify-center'>
          <p className='text-2xl font-bold text-navy-700 dark:text-white'>
            {NumberHelper.shortenNumber(data.activity_count)}
          </p>
          <p className='text-sm font-normal text-gray-600'>Activity count</p>
        </div>
        <div className='flex flex-col items-center justify-center'>
          <p className='text-2xl font-bold text-navy-700 dark:text-white'>
            {data.deal_import_count}
          </p>
          <p className='text-sm font-normal text-gray-600'>Deals imported</p>
        </div>
      </div>
    </Card>
  );
};

export default Banner;
