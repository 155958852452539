import DataTable from 'components/table/DataTable';
import { hasPermissionTo } from 'helpers/AuthHelper';
import DateTimeHelper from 'helpers/DateTimeHelper';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CreateForm from './components/CreateForm';
import { useStoreActions } from 'easy-peasy';

const Users = () => {
  const { page = 1 } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const searchQuery = searchParams.get('q') ?? '';
  const queryClient = useQueryClient();
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const openModal = useStoreActions((actions) => actions.modal.openModal);
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  const customCellRenderer = (data, header, modelId) => {
    if (header === 'created at') {
      return <div>{DateTimeHelper.formatDate(data)}</div>;
    }
    if (header === 'role') {
      const role = data[0]?.name;
      let color = 'bg-gray-700';
      if (role === 'link builder') {
        color = 'bg-blue-500';
      }
      if (role === 'viewer') {
        color = 'bg-yellow-500';
      }
      if (!role) {
        return null;
      }
      return (
        <div
          className={`w-20 rounded px-2 py-1 text-center capitalize text-white ${color}`}
        >
          {role}
        </div>
      );
    }
    return null;
  };

  const onPageChange = (pageNumber) => {
    navigate('/admin/users/' + (pageNumber.selected + 1));
  };

  const onTableSearch = (query) => {
    navigate('/admin/users');
    setSearchParams({ q: query });
  };

  return (
    <div>
      {hasPermissionTo('create users') && (
        <>
          <CreateForm
            isOpen={showCreateForm}
            onClose={() => setShowCreateForm(false)}
          />
        </>
      )}
      <div className='mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6'>
        <div className={`col-span-6 transition`}>
          <DataTable
            columnsData={[
              {
                Header: 'id',
                accessor: 'id',
              },
              {
                Header: 'name',
                accessor: 'name',
              },
              {
                Header: 'email',
                accessor: 'email',
              },
              {
                Header: 'role',
                accessor: 'roles',
              },
              {
                Header: 'created at',
                accessor: 'created_at',
              },
              {
                Header: 'actions',
              },
            ]}
            customCellRenderer={customCellRenderer}
            modelName='user'
            request='users'
            editable
            deletable
            onCreateButtonClick={
              hasPermissionTo('create users')
                ? () => setShowCreateForm(true)
                : null
            }
            onEditClick={(modelId) => {
              openModal({
                name: 'edit-user',
                props: {
                  modelId,
                },
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;
