import Card from 'components/card';
import ChartDropdown from 'components/dropdown/ChartDropdown';
import PriceHelper from 'helpers/PriceHelper';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import { useQuery } from 'react-query';
import StatisticService from 'services/StatisticService';
import { topWebsitesChartOptions } from 'variables/charts';

const TopWebsites = ({ initialData }) => {
  const [timeFrame, setTimeFrame] = useState('all-time');
  const [timeFrameChanged, setTimeFrameChanged] = useState(false);
  const { data, isLoading, refetch } = useQuery(
    ['topWebsites', timeFrame],
    async () => await StatisticService.getTopWebsites(timeFrame),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: timeFrameChanged,
      staleTime: 1000 * 60 * 60,
    },
  );

  function formatNumber(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  let chartOptions = { ...topWebsitesChartOptions };
  chartOptions.xaxis.categories = data
    ? data.map((item) => item.name).reverse()
    : initialData.map((item) => item.name).reverse();

  chartOptions.tooltip.y.formatter = function (val) {
    return PriceHelper.formatNumberAsCurrency(val);
  };

  // format y axis labels
  chartOptions.yaxis.labels.formatter = function (val) {
    return PriceHelper.formatNumberAsCurrency(val);
  };

  const onChangeTimeFrame = (timeframe) => {
    if (!timeFrameChanged) {
      setTimeFrameChanged(true);
    }
    setTimeFrame(timeframe);
  };

  const timeframeTitles = {
    'last-month': 'Last month',
    'last-6-months': 'Last 6 months',
    'last-12-months': 'Last 12 months',
    'all-time': 'All time',
  };

  return (
    <Card extra='pb-7 p-[20px]'>
      <div className='flex flex-row justify-between'>
        <div className='ml-1 flex flex-col pt-2'>
          <div className='text-xl font-bold text-navy-700 dark:text-white'>
            Top Websites
          </div>
          <span className='text-sm font-medium text-gray-600'>
            {timeframeTitles[timeFrame]} • By money spent
          </span>
        </div>
        <div className='mt-2 flex items-start'>
          <ChartDropdown onChangeTimeFrame={onChangeTimeFrame} />
        </div>
      </div>

      <div className='h-[300px] w-full pb-0'>
        <Chart
          options={chartOptions}
          series={[
            {
              name: 'Links',
              data: data
                ? data.map((item) => item.deal_links_count).reverse()
                : initialData.map((item) => item.deal_links_count).reverse(),
            },
          ]}
          type='bar'
          width='100%'
          height='100%'
        />
      </div>
    </Card>
  );
};

export default TopWebsites;
