import DataTable from 'components/table/DataTable';
import DateTimeHelper from 'helpers/DateTimeHelper';
import { useState } from 'react';
import CreateModal from './components/CreateModal';
import EditModal from './components/EditModal';

const Currencies = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);

  const customCellRenderer = (data, header, modelId) => {
    if (header === 'created at') {
      return <div>{DateTimeHelper.formatDate(data)}</div>;
    }
    return null;
  };

  return (
    <div>
      <CreateModal
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      />
      <div className='mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6'>
        <div className={`col-span-6 transition`}>
          <DataTable
            columnsData={[
              {
                Header: 'id',
                accessor: 'id',
              },
              {
                Header: 'name',
                accessor: 'name',
              },
              {
                Header: 'code',
                accessor: 'code',
              },
              {
                Header: 'created at',
                accessor: 'created_at',
              },
              {
                Header: 'actions',
              },
            ]}
            customCellRenderer={customCellRenderer}
            modelName='currency'
            request='currencies'
            EditModal={EditModal}
            editable
            deletable
            onCreateButtonClick={() => setShowCreateModal(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default Currencies;
