class PriceHelper {
  static formatNumberAsCurrency = (number, currency = 'EUR') => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };
}

export default PriceHelper;
