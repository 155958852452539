import {
  Timeline,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineItem,
} from '@material-tailwind/react';
import Card from 'components/card';
import Modal from 'components/modals/Modal';
import DateTimeHelper from 'helpers/DateTimeHelper';
import { IoIosCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';
import { IoCloseCircle, IoWarning } from 'react-icons/io5';
import { MdCheck, MdCheckCircle, MdClose, MdWarning } from 'react-icons/md';
import { useQuery } from 'react-query';
import ApiService from 'services/ApiService';

export default function CrawlerModal({ linkId = null, onClose = null }) {
  const {
    data: crawls,
    isLoading,
    isError,
  } = useQuery(
    ['links', linkId, 'crawls'],
    async () => {
      // add start and end month if they exist
      const request = `links/${linkId}/crawls`;
      return await ApiService.get(request);
    },
    {
      enabled: linkId != null,
    },
  );

  const getStatusColor = (status) => {
    switch (status) {
      case 'active':
        return 'green';
      case 'failed':
        return 'red';
      case 'warning':
        return 'yellow';
      default:
        return 'gray';
    }
  };

  const getIcon = (status) => {
    switch (status) {
      case 'active':
        return <MdCheck className='text-green-600' />;
      case 'failed':
        return <MdClose className='text-red-600' />;
      default:
        return <MdWarning className='text-yellow-600' />;
    }
  };

  if (isLoading) {
    return null;
  }
  return (
    <Modal
      title={`Crawls for link: ${linkId}`}
      buttons={
        <div className='flex justify-end gap-2'>
          <button
            onClick={onClose}
            className='linear w-40 rounded-xl bg-lightPrimary px-5 py-2.5 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100'
          >
            Close
          </button>
        </div>
      }
    >
      <div>
        <div className='max-h-[50vh] overflow-y-scroll p-3'>
          <Timeline>
            {crawls?.data?.data?.map((crawl) => (
              <TimelineItem className='mb-4'>
                <TimelineConnector className='!w-[78px]' />
                <TimelineHeader className='border-blue-gray-50 shadow-blue-gray-900/5 relative min-w-[500px] rounded-xl border bg-white py-2 pl-3 pr-6 shadow-lg'>
                  <TimelineIcon
                    className='p-3'
                    variant='ghost'
                    color={getStatusColor(crawl.status)}
                  >
                    {getIcon(crawl.status)}
                  </TimelineIcon>
                  <div className='flex flex-col gap-1 text-gray-700'>
                    <span className='text-sm font-semibold'>
                      {crawl.anchor_text ? (
                        crawl.anchor_text
                      ) : (
                        <span className='text-gray-700'>
                          Anchor text was not found
                        </span>
                      )}
                    </span>
                    <span className='text-sm font-normal text-gray-700'>
                      {DateTimeHelper.formatDate(crawl.created_at)}
                    </span>
                  </div>
                </TimelineHeader>
              </TimelineItem>
            ))}
          </Timeline>
        </div>
      </div>
    </Modal>
  );
}
