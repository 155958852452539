import {
  IconButton,
  MenuItem,
  SpeedDial,
  SpeedDialAction,
  SpeedDialContent,
  SpeedDialHandler,
  Typography,
} from '@material-tailwind/react';
import DataTable from 'components/table/DataTable';
import DateTimeHelper from 'helpers/DateTimeHelper';
import PriceHelper from 'helpers/PriceHelper';
import { useState } from 'react';
import { BsFilter } from 'react-icons/bs';
import { IoMdCheckmark } from 'react-icons/io';
import { IoDocumentOutline, IoWarning } from 'react-icons/io5';
import { MdClose } from 'react-icons/md';
import { useQueryClient } from 'react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import CrawlerModal from './components/CrawlerModal';
import CsvExportButton from './components/CsvExportButton';
import { useStoreActions } from 'easy-peasy';
import { CiFilter } from 'react-icons/ci';
import { NumberParam, useQueryParam } from 'use-query-params';

const Links = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get('q') ?? '';
  const queryClient = useQueryClient();
  const [selectedCrawler, setSelectedCrawler] = useState(null);
  const [status, setStatus] = useState(null);
  const navigate = useNavigate();
  const openModal = useStoreActions((actions) => actions.modal.openModal);
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);
  const [websiteId, setWebsiteId] = useQueryParam('website_id', NumberParam);
  const [currentPage, setCurrentPage] = useQueryParam('page', NumberParam);

  const customCellRenderer = (data, header, modelId) => {
    if (header === 'created at') {
      return <div>{DateTimeHelper.formatDate(data)}</div>;
    }
    if (header === 'anchor text') {
      return <div className='flex items-center gap-1'>{data}</div>;
    }
    if (header === 'deal id') {
      return (
        <Link to={`/admin/deals/${data}/edit`} className='text-brand-500'>
          {data}
        </Link>
      );
    }
    if (header === 'target url' || header === 'referring site') {
      return (
        <a target='_blank' href={data} className='text-brand-500'>
          {data}
        </a>
      );
    }
    if (header === 'real price') {
      return <div>{data ? '€' + data : null}</div>;
    }
    if (header === 'status') {
      let color = null;
      if (data == null) {
        color = 'bg-gray-300';
      } else if (data?.status == 'active') {
        color = 'bg-green-500';
      } else if (data?.status === 'failed') {
        color = 'bg-red-500';
      } else {
        color = 'bg-yellow-500';
      }
      return (
        <button
          onClick={() =>
            openModal({
              name: 'view-crawls',
              props: {
                linkId: modelId,
              },
            })
          }
          disabled={color === 'bg-gray-300'}
          className={`flex ${color} aspect-square w-3 rounded text-white`}
        ></button>
      );
    }
    return null;
  };

  return (
    <div>
      <div className='mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6'>
        <div className={`col-span-6 transition`}>
          <DataTable
            columnsData={[
              {
                Header: 'id',
                accessor: 'id',
                sortable: true,
              },
              {
                Header: 'target url',
                accessor: 'target_url',
                colSpan: 3,
                sortable: true,
              },
              {
                Header: 'referring site',
                accessor: 'referring_site',
                colSpan: 3,
                sortable: true,
              },
              {
                Header: 'price',
                accessor: 'price',
                sortable: true,
              },
              {
                Header: 'real price',
                accessor: 'real_price',
                sortable: true,
              },
              {
                Header: 'anchor text',
                accessor: 'anchor_text',
                colSpan: 2,
                popover: true,
                sortable: true,
              },
              {
                Header: 'deal id',
                accessor: 'deal_id',
                sortable: true,
              },
              {
                Header: 'live month',
                accessor: 'live_date',
              },
              {
                Header: 'status',
                accessor: 'latest_crawl',
              },
              {
                Header: 'actions',
              },
            ]}
            customCellRenderer={customCellRenderer}
            modelName='link'
            request='links'
            filters={{
              status,
              websiteId,
            }}
            deletable
            editable
            onEditClick={(modelId = null) => {
              console.log(modelId);
              openModal({
                name: 'edit-link',
                props: {
                  modelId: modelId,
                },
              });
            }}
            AdditionalActions={() => (
              <>
                <SpeedDial placement='right'>
                  <SpeedDialHandler>
                    <div className='flex cursor-pointer flex-row items-center gap-2 rounded-full bg-gray-100 text-gray-700'>
                      <IconButton
                        size='md'
                        className={`rounded-full ${
                          status === 'active' && 'bg-green-500'
                        } ${status === 'warning' && 'bg-yellow-500'}  ${
                          status === 'failed' && 'bg-red-500'
                        }`}
                      >
                        <BsFilter className='h-5 w-5 text-white transition-transform group-hover:rotate-45' />
                      </IconButton>
                      <Typography className='pr-3 text-sm'>
                        Filter by status
                      </Typography>
                    </div>
                  </SpeedDialHandler>
                  <SpeedDialContent className='flex-row'>
                    <SpeedDialAction
                      onClick={() => {
                        navigate(`/admin/links`);
                        status !== 'active'
                          ? setStatus('active')
                          : setStatus(null);
                      }}
                      className='h-16 w-16'
                    >
                      <IoMdCheckmark className='h-5 w-5 text-green-500' />
                      <Typography
                        color='blue-gray'
                        className='text-xs font-normal'
                      >
                        Active
                      </Typography>
                    </SpeedDialAction>
                    <SpeedDialAction
                      onClick={() => {
                        navigate(`/admin/links`);
                        status !== 'warning'
                          ? setStatus('warning')
                          : setStatus(null);
                      }}
                      className='h-16 w-16'
                    >
                      <IoWarning className='h-5 w-5 text-yellow-500' />
                      <Typography
                        color='blue-gray'
                        className='text-xs font-normal'
                      >
                        Warning
                      </Typography>
                    </SpeedDialAction>
                    <SpeedDialAction
                      onClick={() => {
                        navigate(`/admin/links`);
                        status !== 'failed'
                          ? setStatus('failed')
                          : setStatus(null);
                      }}
                      className='h-16 w-16'
                    >
                      <MdClose className='h-5 w-5 text-red-500' />
                      <Typography
                        color='blue-gray'
                        className='text-xs font-normal'
                      >
                        Failed
                      </Typography>
                    </SpeedDialAction>
                  </SpeedDialContent>
                </SpeedDial>
                <button
                  onClick={() =>
                    openModal({
                      name: 'advanced-filters',
                      props: {
                        onConfirm: (advancedSearchData) => {
                          const { websiteId, startDate, endDate } =
                            advancedSearchData;
                          setWebsiteId(websiteId);
                          setCurrentPage(1);
                        },
                      },
                    })
                  }
                  className='flex items-center gap-1 rounded-xl bg-gray-100 p-2'
                >
                  <CiFilter className='h-5 w-5 text-gray-500' />
                  <div>Advanced search</div>
                  <div className='flex size-4 items-center justify-center rounded-full bg-brand-500 text-xs text-white'>
                    3
                  </div>
                </button>
              </>
            )}
            // editModal={EditForm}
            MenuActions={() => (
              <>
                <CsvExportButton
                  endpoint='/links/export'
                  additionalParams={{
                    status: status,
                  }}
                />
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Links;
