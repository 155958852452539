import Card from 'components/card';
import ErrorHelper from 'helpers/ErrorHelper';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEye } from 'react-icons/fa';
import { QueryClient, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ApiService from 'services/ApiService';

const UpdatePassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    clearErrors,
    getValues,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm();

  const [passwordReveals, setPasswordReveals] = useState([]);
  const queryClient = useQueryClient();

  const updatePasswordMutation = useMutation(async () => {
    await ApiService.post('update-password', {
      current_password: getValues('current_password'),
      password: getValues('password'),
      confirm_password: getValues('confirm_password'),
    });
  });

  const submit = () => {
    const toastId = toast.loading('Updating password...');
    updatePasswordMutation.mutate(null, {
      onSuccess: () => {
        toast.update(toastId, {
          type: 'success',
          render: 'Password updated!',
          isLoading: false,
          autoClose: 3000,
        });
        reset(getValues());
        queryClient.invalidateQueries(['profile']);
      },
      onError: (error) => {
        if (error.response && error.response.status === 422) {
          return ErrorHelper.handleValidationErrors(
            error.response.data.errors,
            toastId,
          );
        }
        toast.update(toastId, {
          type: 'error',
          render: 'Password could not be updated!',
          isLoading: false,
          autoClose: 3000,
        });
      },
    });
  };

  const handlePasswordReveal = (index) => {
    // Create a copy of the passwordReveals array to avoid mutating state directly
    const reveals = [...passwordReveals];

    // Check if the index is already present in the array
    const indexPosition = reveals.indexOf(index);

    if (indexPosition !== -1) {
      // If index is present, remove it
      reveals.splice(indexPosition, 1);
    } else {
      // If index is not present, add it
      reveals.push(index);
    }

    // Update the passwordReveals state with the modified array
    setPasswordReveals(reveals);
  };

  return (
    <Card extra={'w-full h-full p-4'}>
      <div className='mb-6 text-xl font-bold'>Update password</div>
      <div className='flex flex-col gap-4 text-[16px]'>
        <div className='flex flex-col justify-center gap-2 rounded-2xl border bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <span className='text-gray-500'>Current password</span>
          <div className='flex items-center rounded-lg border bg-lightPrimary'>
            <input
              {...register('current_password', {
                required: true,
                min: 5,
                max: 100,
              })}
              type={passwordReveals.includes(0) ? 'text' : 'password'}
              placeholder='Enter current password...'
              className='grow rounded-lg bg-lightPrimary p-3 outline-0 transition focus:border-brand-300'
            />
            <button
              className='px-2 py-1 text-gray-500 transition hover:text-gray-700'
              onClick={() => handlePasswordReveal(0)}
            >
              <FaEye />
            </button>
          </div>
        </div>
        <div className='flex flex-col justify-center gap-2 rounded-2xl border bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <span className='text-gray-500'>New password</span>
          <div className='flex items-center rounded-lg border bg-lightPrimary'>
            <input
              {...register('password', {
                required: true,
                min: 5,
                max: 100,
              })}
              type={passwordReveals.includes(1) ? 'text' : 'password'}
              placeholder='Enter new password...'
              className='grow rounded-lg bg-lightPrimary p-3 outline-0 transition focus:border-brand-300'
            />
            <button
              className='px-2 py-1 text-gray-500 transition hover:text-gray-700'
              onClick={() => handlePasswordReveal(1)}
            >
              <FaEye />
            </button>
          </div>
        </div>
        <div className='flex flex-col justify-center gap-2 rounded-2xl border bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <span className='text-gray-500'>Confirm password</span>
          <div className='flex items-center rounded-lg border bg-lightPrimary'>
            <input
              {...register('confirm_password', {
                required: true,
                min: 5,
                max: 100,
              })}
              type={passwordReveals.includes(2) ? 'text' : 'password'}
              placeholder='Enter password confirmation...'
              className='grow rounded-lg bg-lightPrimary p-3 outline-0 transition focus:border-brand-300'
            />
            <button
              className='px-2 py-1 text-gray-500 transition hover:text-gray-700'
              onClick={() => handlePasswordReveal(2)}
            >
              <FaEye />
            </button>
          </div>
        </div>
        <div>
          <button
            className={`w-full rounded-xl bg-brand-100 px-4 py-4 text-brand-700 transition ${
              !isDirty && 'opacity-0'
            }`}
            disabled={!isDirty}
            onClick={submit}
          >
            Update
          </button>
        </div>
      </div>
    </Card>
  );
};

export default UpdatePassword;
