import { easyPeasyStore } from 'store';

export const hasPermissionTo = (permission) => {
  if (!easyPeasyStore.getState().auth.user) {
    return false;
  }
  if (easyPeasyStore.getState().auth.permissions.includes(permission)) {
    return true;
  }
  return false;
};
