import { ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { modalElementVariant } from 'animations';
import Card from 'components/card';
import { AnimatePresence, m } from 'framer-motion';
import Modal from './Modal';

const ConfirmationModal = ({
  title = 'Confirm deletion',
  onConfirm = () => {},
}) => {
  return (
    <Modal title={title} onConfirm={onConfirm}>
      <span>Are you sure you want to proceed?</span>
    </Modal>
  );
};
export default ConfirmationModal;
