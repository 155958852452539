import axios from 'axios';
import { easyPeasyStore } from 'store';

const ApiService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000, // Request timeout in milliseconds
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

ApiService.interceptors.request.use(
  (config) => {
    const token = easyPeasyStore.getState().auth.token || null;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

ApiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      const logoutAction = easyPeasyStore.getActions().auth.logout;
      await easyPeasyStore.dispatch(logoutAction());
      window.location.replace('/auth/sign-in');
    }
    return Promise.reject(error);
  },
);

export default ApiService;
