import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Card } from '@material-tailwind/react';
import ImportLinksForm from 'components/forms/ImportLinksForm';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiService from 'services/ApiService';
import { defaultToastOptions } from 'options';
import ErrorHelper from 'helpers/ErrorHelper';

export default function ImportLinksModal({ isOpen, onClose }) {
  const {
    register,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm();
  const [openAccordion, setOpenAccordion] = useState(1);
  const [linkFiles, setLinkFiles] = useState([]);
  const [category, setCategory] = useState(null);
  const [liveMonth, setLiveMonth] = useState(null);
  const [currency, setCurrency] = useState(null);

  const handleOpenAccordion = (id) => {
    setOpenAccordion(id);
  };
  const navigate = useNavigate();
  const token = useStoreState((state) => state.auth.token);
  const { id: dealId } = useParams();
  const queryClient = useQueryClient();
  const openModal = useStoreActions((actions) => actions.modal.openModal);

  const onSubmit = () => {
    const data = getValues();
    let formData = new FormData();
    if (linkFiles.length > 0)
      formData.append('links_file', linkFiles[0]?.file ?? null);
    if (data.sheet_url) {
      formData.append('sheet_url', data.sheet_url);
    }
    if (currency) formData.append('currency', currency);
    formData.append('category', category);
    formData.append('live_month', liveMonth);

    let config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + `/deals/${dealId}/import`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    };
    const toastId = toast.loading('Importing links...');
    let response = ApiService.request(config)
      .then((response) => {
        toast.update(toastId, {
          render: 'Links imported successfully',
          type: 'success',
          ...defaultToastOptions,
        });
        queryClient.invalidateQueries([`deals/${dealId}/links`]);
        onClose();
      })
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          toast.dismiss();
          onClose();
          return openModal({
            name: 'bulk-import-error-modal',
            props: {
              message: ErrorHelper.handleValidationErrors(
                error.response.data.errors,
                toastId,
              ),
            },
          });
        }
        ErrorHelper.handleApiError(error.response.status, toastId);
      });
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      scrollBehavior='inside'
      isOpen={isOpen}
      onClose={onClose}
      className='!z-[1010]'
    >
      <ModalOverlay className='z-40 bg-gray-800/70 backdrop-blur-sm backdrop-brightness-50' />
      <ModalContent className='!z-[1002] !m-auto !w-max min-w-[550px] !max-w-[85%] md:top-[12vh]'>
        <ModalBody>
          <Card className='p-6'>
            <h1 className='mb-[20px] text-2xl font-bold'>Import Links</h1>
            <div className='flex flex-col gap-6'>
              <ImportLinksForm
                openAccordion={openAccordion}
                handleOpenAccordion={handleOpenAccordion}
                register={register}
                errors={errors}
                // control={control}
                setValue={setValue}
                linkFiles={linkFiles}
                updateFiles={setLinkFiles}
                getValues={getValues}
                onCategoryChange={(category) => {
                  setCategory(category);
                }}
                onCurrencyChange={(currency) => {
                  setCurrency(currency);
                }}
                onLiveMonthChange={(liveMonth) => {
                  setLiveMonth(liveMonth);
                }}
              />
              <div className='flex justify-end gap-2'>
                <button
                  onClick={onClose}
                  className='linear w-1/2 rounded-xl px-5 py-2.5 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100'
                >
                  Cancel
                </button>
                <button
                  onClick={onSubmit}
                  disabled={
                    linkFiles?.length === 0 &&
                    getValues('sheet_url') != undefined
                  }
                  className='linear w-1/2 rounded-xl bg-indigo-500 px-5 py-2.5 text-base font-medium text-white transition duration-200 hover:bg-indigo-600 disabled:bg-gray-500'
                >
                  Import
                </button>
              </div>
            </div>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
