import ApiService from './ApiService';

const UnitService = {
  getAll: async (page = 1, query = '') => {
    const response = await ApiService.get(
      `/units?search=${query}&page=${page}`,
    );
    return response.data;
  },
  bulkDelete: async (ids) => {
    const response = await ApiService.post('units/bulk-delete', {
      ids,
    });
    return response.data;
  },
  delete: async (id) => {
    const response = await ApiService.delete('units/' + id);
    return response.data;
  },
  get: async (id) => {
    const response = await ApiService.get('units/' + id);
    return response.data;
  },
  create: async (data) => {
    const response = await ApiService.post('units', data);
    return response.data;
  },
  update: async (id, data) => {
    const response = await ApiService.put('units/' + id, data);
    return response.data;
  },
};

export default UnitService;
