import { Dropzone, FileMosaic } from '@files-ui/react';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Input,
} from '@material-tailwind/react';
import { FileUploadRounded } from '@mui/icons-material';
import DateTimeHelper from 'helpers/DateTimeHelper';
import { customSelectStyles } from 'options';
import { useEffect, useState } from 'react';
import { BsFileSpreadsheetFill } from 'react-icons/bs';
import { FaFileCsv } from 'react-icons/fa';
import { IoIosArrowDown } from 'react-icons/io';
import Select from 'react-select';

const categories = [
  {
    value: 'image',
    label: 'Image',
  },
  {
    value: 'nofollow',
    label: 'Nofollow',
  },
  {
    value: 'permalink',
    label: 'Permalink',
  },
  {
    value: 'organic',
    label: 'Organic',
  },
  {
    value: 'insertion',
    label: 'Insertion',
  },
  {
    value: 'campaign',
    label: 'Campaign',
  },
];

const currencies = [
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'DKK',
    label: 'DKK',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'JPY',
    label: 'JPY',
  },
  {
    value: 'CHF',
    label: 'CHF',
  },
];

const ImportLinksForm = ({
  openAccordion,
  handleOpenAccordion,
  register,
  updateFiles,
  linkFiles,
  onCategoryChange,
  onCurrencyChange,
  onLiveMonthChange,
}) => {
  const [liveMonth, setLiveMonth] = useState(
    DateTimeHelper.getYearAndMonth(
      DateTimeHelper.substractMonths(new Date(), 1),
    ),
  );
  useEffect(() => {
    onLiveMonthChange(liveMonth);
  }, [liveMonth]);
  return (
    <div className='flex flex-col gap-2'>
      <Accordion
        icon={
          <IoIosArrowDown
            className={`transform text-sm ${
              openAccordion === 2 ? 'rotate-180' : ''
            }`}
          />
        }
        className='mb-2 rounded-lg border border-gray-100 px-4 shadow-md'
        open={openAccordion == 2}
      >
        <AccordionHeader
          className={`border-0 text-lg ${
            openAccordion == 2 ? 'text-brand-500' : 'text-gray-500'
          }`}
          onClick={() => handleOpenAccordion(2)}
        >
          <div className='flex items-center gap-3'>
            <BsFileSpreadsheetFill className='text-xl' />
            <span>Import from Google Sheet</span>
          </div>
        </AccordionHeader>
        <AccordionBody>
          <Input
            type='text'
            variant='standard'
            label='Enter Google Sheet URL'
            placeholder='Enter Google Sheet URL...'
            {...register('sheet_url', {
              required: {
                value: linkFiles.length === 0,
                message: 'Google Sheet URL is required',
              },
              pattern: {
                value:
                  /^(https?:\/\/)?(www\.)?docs\.google\.com\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/,
                message: 'Invalid Google Sheet URL',
              },
            })}
          />
        </AccordionBody>
      </Accordion>
      <Accordion
        icon={
          <IoIosArrowDown
            className={`transform text-sm ${
              openAccordion === 1 ? 'rotate-180' : ''
            }`}
          />
        }
        className='mb-2 rounded-lg border border-gray-100 px-4 shadow-md'
        open={openAccordion == 1}
      >
        <AccordionHeader
          className={`border-0 text-lg ${
            openAccordion == 1 ? 'text-brand-500' : 'text-gray-500'
          }`}
          onClick={() => handleOpenAccordion(1)}
        >
          <div className='flex items-center gap-3'>
            <FaFileCsv className='text-xl' />
            <span>Import from CSV file</span>
          </div>
        </AccordionHeader>
        <AccordionBody>
          <div className='flex flex-col gap-4'>
            <div>
              <a
                href={`${process.env.REACT_APP_API_URL}/deals/import-template`}
                className='inline-flex items-center gap-2 rounded-lg bg-brand-500 px-4 py-2 text-white'
              >
                <span>Get CSV Template</span>
              </a>
            </div>
            <Dropzone
              color={'#6366f1'}
              onChange={updateFiles}
              value={linkFiles}
              maxFiles={1}
              behaviour='replace'
              label={
                <div className='flex items-center gap-2'>
                  <FileUploadRounded />
                  <div>Upload CSV here...</div>
                </div>
              }
              header={true}
              accept='text/csv'
              className='bg-lightPrimary !font-dm'
            >
              {linkFiles.map((file, index) => (
                <FileMosaic key={index} {...file} preview />
              ))}
            </Dropzone>
          </div>
        </AccordionBody>
      </Accordion>
      <div className='flex flex-row items-center gap-4 p-4'>
        <div className='flex w-1/2 flex-col'>
          <p className='text-sm text-gray-700'>Category*</p>
          <Select
            options={categories}
            isClearable
            placeholder='Select a category...'
            styles={customSelectStyles}
            onChange={(selectedOption) => {
              onCategoryChange(selectedOption.value);
            }}
          />
        </div>
        <div className='flex w-1/2 flex-col'>
          <p className='text-sm text-gray-700'>Currency*</p>
          <Select
            options={currencies}
            isClearable
            placeholder='Select a currency...'
            styles={customSelectStyles}
            onChange={(selectedOption) => {
              onCurrencyChange(selectedOption.value);
            }}
          />
        </div>
        <div className='flex w-1/2 flex-col'>
          <p className='text-sm text-gray-700'>Live month*</p>
          <input
            className='rounded-0 w-full border-b py-2 text-sm outline-0'
            type='month'
            id='start'
            onChange={(e) => setLiveMonth(e.target.value)}
            value={liveMonth}
          />
        </div>
      </div>
    </div>
  );
};

export default ImportLinksForm;
