const DateTimeHelper = {
  formatDate: (apiDate) => {
    const date = new Date(apiDate);

    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  },
  getYearAndMonth: (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index

    const monthString = month < 10 ? '0' + month : month.toString();

    // Return the formatted string
    return `${year}-${monthString}`;
  },
  formatTime: (date) => {
    return new Date(date).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
  },
  substractMonths: (date, months) => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - months);
    return newDate;
  },
};

export default DateTimeHelper;
