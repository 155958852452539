import Card from 'components/card';
import LineChart from 'components/charts/LineChart';
import resolveConfig from 'tailwindcss/resolveConfig';
import { dealGrowthChartOptions } from 'variables/charts';
import tailwindConfig from '../../../../tailwind.config';
const twConfig = resolveConfig(tailwindConfig);

const DealGrowth = ({ data }) => {
  const chartData = [
    {
      name: 'No. of Deals',
      data: data.map((item) => item.deal_count),
    },
  ];

  const calculateTotalSpent = (data) => {
    let totalSpent = 0;
    data.forEach((item) => {
      totalSpent += item.value;
    });
    return totalSpent;
  };

  // Clone the chart options to avoid modifying the original object
  const chartOptions = { ...dealGrowthChartOptions };
  chartOptions.xaxis.categories = data.map((item) =>
    new Date(item.month).getTime(),
  );
  chartOptions.tooltip.y.formatter = function (val) {
    return val;
  };
  chartOptions.yaxis.min = 0;
  chartOptions.yaxis.max = Math.max(...data.map((item) => item.deal_count));

  return (
    <Card extra='text-center overflow-hidden h-full p-[20px]'>
      <div className='flex flex-row items-center justify-between'>
        <p className='text-xl font-bold text-navy-700 dark:text-white'>
          Deals by month
        </p>
      </div>

      <div className='flex h-full w-full flex-col justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden'>
        <div className='h-[300px] w-full'>
          <LineChart options={chartOptions} series={chartData} />
        </div>
      </div>
    </Card>
  );
};

export default DealGrowth;
