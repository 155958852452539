import Loading from 'components/loading/Loading';
import { useQuery } from 'react-query';
import ApiService from 'services/ApiService';
import Banner from './components/Banner';
import ProfileInformation from './components/ProfileInformation';
import UpdatePassword from './components/UpdatePassword';

const ProfileOverview = () => {
  const { data, isLoading, isFetching } = useQuery(
    ['profile'],
    async () => {
      const res = await ApiService.get('/profile');
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className='flex w-full flex-col gap-5'>
      <div className='w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12'>
        <div className='col-span-4 lg:!mb-0'>
          <Banner data={data} />
        </div>

        <div className='col-span-4 lg:!mb-0'>
          <ProfileInformation data={data} />
        </div>

        <div className='z-0 col-span-4 lg:!mb-0'>
          <UpdatePassword />
        </div>
      </div>
      {/* all project & ... */}
    </div>
  );
};

export default ProfileOverview;
