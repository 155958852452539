import { ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { modalElementVariant } from 'animations';
import Card from 'components/card';
import { useStoreActions } from 'easy-peasy';
import { AnimatePresence, m } from 'framer-motion';
import { IoClose } from 'react-icons/io5';

const Modal = ({
  title = '',
  children,
  buttons = null,
  onConfirm = () => {},
}) => {
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  return (
    <m.div
      variants={modalElementVariant}
      initial='hidden'
      animate='visible'
      exit='hidden'
      className='fixed inset-60 z-[1000] flex items-center justify-center pb-32'
    >
      <div>
        <Card extra='px-[30px] pt-[35px] pb-[40px] max-w-[50vw] flex flex-col !z-[1004] min-w-[400px]'>
          <div className='mb-[20px] flex items-center justify-between'>
            <h1 className='text-2xl font-bold'>
              {title ? title : 'Are you sure?'}
            </h1>
            <button
              onClick={() => closeModal()}
              className='rounded-lg bg-gray-100 p-1 transition hover:bg-gray-300'
            >
              <IoClose className='text-xl text-gray-700' />
            </button>
          </div>
          <div className='mb-[20px]'>{children}</div>
          {buttons ?? (
            <div className='flex gap-2'>
              <button
                onClick={() => closeModal()}
                className='linear w-full rounded-xl px-5 py-3 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-600/5 active:bg-gray-700/5 dark:bg-gray-400/10 dark:text-white dark:hover:bg-gray-300/10 dark:active:bg-gray-200/10'
              >
                Cancel
              </button>
              <button
                onClick={() => onConfirm()}
                className='linear w-full rounded-xl bg-brand-500  px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-600 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30'
              >
                Confirm
              </button>
            </div>
          )}
        </Card>
      </div>
    </m.div>
  );
};
export default Modal;
