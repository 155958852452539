import { Option, Select, Textarea } from '@material-tailwind/react';
import Card from 'components/card';
import DatePicker from 'components/inputs/DatePicker';
import Loading from 'components/loading/Loading';
import { useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaCheckCircle, FaHandshake } from 'react-icons/fa';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiService from 'services/ApiService';

export default function DealInfo({ getStatus = null, deal }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);
  const [linkFiles, setLinkFiles] = useState([]);
  const [invoiceFiles, setInvoiceFiles] = useState([]);
  const token = useStoreState((state) => state.auth.token);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    reset,
    formState: { errors, isDirty, dirtyFields },
  } = useForm();
  const { id } = useParams();
  const queryClient = useQueryClient();

  useEffect(() => {
    setValue('invoice_due', new Date(deal?.invoice_due));
    setValue('client_id', deal?.client_id);
    setValue('company_id', deal?.company_id);
    setValue('notes', deal?.notes);
  }, [deal]);

  const { data: clients, isLoading } = useQuery(
    'clients',
    async () => {
      const res = await ApiService.get(
        '/clients?per_page=100&fields[clients]=id,name',
      );
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  );

  const { data: companies } = useQuery(
    'companies',
    async () => {
      const res = await ApiService.get(
        '/companies?per_page=100&fields[companies]=id,name',
      );
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  );

  const updateDealMutation = useMutation((data) => {
    return ApiService.put(`/deals/${id}`, data);
  });

  const updateNotesMutation = useMutation(
    (data) => {
      return ApiService.patch(`/deals/${id}/notes`, data);
    },
    {
      onSuccess: () => {
        reset({}, { keepValues: true });
      },
    },
  );

  const submit = async () => {
    const data = getValues();
    updateDealMutation.mutate(
      {
        client_id: data.client_id,
        company_id: data.company_id,
        notes: data.notes,
        invoice_due: data.invoice_due,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('deals');
          toast.success('Deal updated successfully');
        },
      },
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Card extra='p-[20px] gap-2 3xl:col-span-2'>
        <div className='flex h-full flex-row items-center justify-between gap-4'>
          <div className='flex w-full flex-col items-center gap-6'>
            <div className='rounded-full bg-indigo-100 p-4'>
              <FaHandshake className='text-5xl text-brand-500' />
            </div>
            <div className='flex flex-col text-center'>
              <div className='flex items-center gap-x-2 text-lg font-bold text-gray-700'>
                <div className='w-full text-center'>Deal {deal?.id}</div>
              </div>
              <span className='mt-2 text-gray-700'>
                Created by {deal?.user_name} on 01.2024
              </span>
            </div>
            <div className='flex items-center rounded-lg border border-green-200 bg-green-100 px-3 py-0.5 text-lg'>
              <IoCheckmarkCircleOutline className='text-2xl text-green-500' />
              <span
                className={`rounded-lg px-2 py-1 capitalize text-green-500`}
              >
                {deal.status}
              </span>
            </div>
          </div>
        </div>
      </Card>
      <Card extra='p-[20px] pointer-events-none 3xl:col-span-4'>
        <p className='mb-6 font-medium text-gray-700'>Deal Information</p>
        <div className='flex flex-col gap-5'>
          {clients && (
            <Select
              disabled
              variant='standard'
              label='Client'
              name='client_id'
              arrow={false}
              {...register('client_id', {
                required: 'Client is required',
              })}
              onChange={(e) => setValue('client_id', e)}
              value={getValues('client_id') || deal.client_id || null}
            >
              {clients?.data?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
          {companies && (
            <Select
              disabled
              variant='standard'
              label='Company'
              name='company_id'
              {...register('company_id', {
                required: 'Client is required',
              })}
              onChange={(e) => setValue('company_id', e)}
              value={getValues('company_id') || deal.company_id || null}
              arrow={false}
            >
              {companies?.data?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
          <div className='flex flex-col gap-1'>
            <span className='text-xs'>Invoice</span>
            <div className='flex items-center justify-between gap-x-2 py-2'>
              <a
                target='_blank'
                rel='noreferrer'
                href={deal.invoice}
                className='pointer-events-auto border-b text-sm'
              >
                View invoice file
              </a>
            </div>
          </div>
          <Controller
            control={control}
            name='invoice_due'
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker
                disabled
                onChange={onChange}
                onBlur={onBlur}
                label='Invoice due date'
                value={value}
              />
            )}
          />
        </div>
      </Card>
      <Card extra='p-[20px] flex flex-col gap-4 3xl:col-span-4'>
        <p className='mb-6 font-medium text-gray-700'>Additional Information</p>
        <div className='relative'>
          <Textarea
            {...register('notes')}
            label='Notes'
            variant='static'
            className='bg-lightPrimary px-2'
          />
          <div className='absolute bottom-4 right-4 flex gap-1'>
            <button
              onClick={() =>
                updateNotesMutation.mutate(
                  {
                    notes: getValues('notes'),
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries('deals');
                      queryClient.invalidateQueries(['deals', id]);
                      toast.success('Notes updated successfully');
                    },
                  },
                )
              }
            >
              <FaCheckCircle
                className={`transform text-xl text-green-500 transition duration-500 ${
                  dirtyFields.notes ? 'scale-100' : 'scale-0'
                }`}
              />
            </button>
          </div>
        </div>
        <div>
          <Textarea
            disabled
            label='Message for finance'
            variant='static'
            className='pointer-events-none bg-lightPrimary px-2'
          />
        </div>
      </Card>
    </>
  );
}
