import {
  Input,
  Popover,
  PopoverContent,
  PopoverHandler,
} from '@material-tailwind/react';
import { format } from 'date-fns';
import React from 'react';
import { DayPicker } from 'react-day-picker';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { FcLeft, FcRight } from 'react-icons/fc';

export default function DatePicker({ value, onChange, label, disabled }) {
  return (
    <Popover
      animate={{
        mount: { scale: 1, y: 0, opacity: 1 },
        unmount: { scale: 0.8, y: 10, opacity: 0 },
      }}
      placement='bottom'
    >
      <PopoverHandler>
        <Input
          label={label}
          onChange={(e) => onChange(e.target.value)}
          value={value ? format(value, 'PPP') : ''}
          className='cursor-pointer'
        />
      </PopoverHandler>
      <PopoverContent className='z-[999]'>
        <DayPicker
          disabled={disabled}
          mode='single'
          selected={value}
          onSelect={onChange}
          showOutsideDays
          fromYear={2020}
          className='w-[300px] border-0'
          classNames={{
            caption: 'flex justify-center py-2 mb-4 relative items-center',
            caption_label: 'text-sm font-medium text-gray-900',
            nav: 'flex items-center',
            nav_button:
              'h-6 w-6 bg-transparent hover:bg-blue-gray-50 p-1 rounded-md transition-colors duration-300',
            nav_button_previous: 'absolute left-1.5',
            nav_button_next: 'absolute right-1.5',
            table: 'w-full border-collapse',
            head_row: 'flex font-medium text-gray-900',
            head_cell: 'm-0.5 w-9 font-normal text-sm',
            row: 'flex w-full mt-2',
            cell: 'text-gray-800 rounded-md h-9 w-9 text-center text-sm p-0 m-0.5 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-gray-900/20 [&:has([aria-selected].day-outside)]:text-white [&:has([aria-selected])]:bg-gray-900/50 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
            day: 'h-9 w-9 p-0 font-normal',
            day_range_end: 'day-range-end',
            day_selected:
              'rounded-md bg-brand-500 text-white hover:bg-brand-500 focus:bg-brand-500 focus:text-white',
            day_today: 'rounded-md bg-indigo-100 text-indigo-900',
            day_outside:
              'day-outside text-gray-500 opacity-50 aria-selected:bg-gray-500 aria-selected:text-gray-900 aria-selected:bg-opacity-10',
            day_disabled: 'text-gray-500 opacity-50',
            day_hidden: 'invisible',
          }}
          components={{
            IconLeft: ({ ...props }) => (
              <FaCaretLeft
                {...props}
                className='stroke-2 text-xl text-brand-500'
              />
            ),
            IconRight: ({ ...props }) => (
              <FaCaretRight
                {...props}
                className='stroke-2 text-xl text-brand-500'
              />
            ),
          }}
        />
      </PopoverContent>
    </Popover>
  );
}
