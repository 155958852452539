export const dashboardContainerVariant = {
  visible: {
    transition: {
      staggerChildren: 0.15,
    },
  },
  hidden: {},
};

export const dashboardElementVariant = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      damping: 10,
    },
  },
  hidden: {
    opacity: 0,
    y: 100,
    transition: {
      type: 'spring',
      damping: 10,
    },
  },
};

export const modalContainerVariant = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const modalElementVariant = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      damping: 10,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0,
    transition: {
      type: 'spring',
      damping: 10,
    },
  },
};
