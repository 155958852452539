class NumberHelper {
  static shortenNumber(num) {
    const suffixes = ['', 'k', 'M', 'B', 'T'];
    const magnitude = Math.floor(Math.log10(num) / 3);
    const shortNum = num / Math.pow(10, magnitude * 3);
    const roundedNum = Math.round(shortNum * 10) / 10; // round to 1 decimal place

    // Check if the rounded number has a decimal component
    const formattedNum =
      roundedNum % 1 === 0 ? roundedNum.toFixed(0) : roundedNum.toFixed(1);

    return formattedNum + suffixes[magnitude];
  }
}

export default NumberHelper;
