import { MdBarChart, MdDashboard, MdInfo, MdLink } from 'react-icons/md';
import PieChartCard from 'views/admin/default/components/PieChartCard';
import TotalSpent from 'views/admin/default/components/TotalSpent';

import { dashboardContainerVariant, dashboardElementVariant } from 'animations';
import Loading from 'components/loading/Loading';
import Widget from 'components/widget/Widget';
import { LazyMotion, domAnimation, m } from 'framer-motion';
import { useState } from 'react';
import { FaHandshake, FaUsers } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import StatisticService from 'services/StatisticService';
import TopClients from 'views/admin/default/components/TopClients';
import TopWebsites from 'views/admin/default/components/TopWebsites';
import DealGrowth from './components/DealGrowth';

const Dashboard = () => {
  const {
    data: statistics,
    isLoading,
    isError,
  } = useQuery(['statistics'], async () => await StatisticService.get(), {
    retry: false,
  });
  const [isAnimationEnabled, setIsAnimationEnabled] = useState(true);

  if (isLoading) {
    return <Loading className='mt-4'></Loading>;
  }
  if (isError) {
    return (
      <div className='mb-4 mt-4 inline-flex items-center rounded-lg border border-red-300 bg-red-50 p-4 text-sm text-red-800 dark:border-red-800 dark:bg-gray-800 dark:text-red-400'>
        <MdInfo className='mr-2' size={20} />
        <div>
          <span className='font-medium'>
            Sorry, we couldn't retrieve the requested data. Please check your
            internet connection and try again later.
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className='mt-4'>
      <m.div
        className='mt-3 grid grid-cols-1 gap-5 lg:grid-cols-12'
        variants={isAnimationEnabled && dashboardContainerVariant}
        initial='hidden'
        animate='visible'
      >
        <m.div
          variants={isAnimationEnabled && dashboardElementVariant}
          className='lg:col-span-2'
        >
          <Link to='/admin/performance'>
            <Widget
              icon={<MdBarChart className='h-6 w-6' />}
              title={'Spent this year'}
              subtitle={statistics.spent_this_month}
            />
          </Link>
        </m.div>
        <m.div
          variants={isAnimationEnabled && dashboardElementVariant}
          className='lg:col-span-2'
        >
          <Link to='/admin/links'>
            <Widget
              icon={<MdLink className='h-7 w-7' />}
              title={'Links'}
              subtitle={statistics.link_count}
            />
          </Link>
        </m.div>
        <m.div
          variants={isAnimationEnabled && dashboardElementVariant}
          className='lg:col-span-2'
        >
          <Link to='/admin/websites'>
            <Widget
              icon={<MdDashboard className='h-6 w-6' />}
              title={'Websites'}
              subtitle={statistics.website_count}
            />
          </Link>
        </m.div>
        <m.div
          variants={isAnimationEnabled && dashboardElementVariant}
          className='lg:col-span-2'
        >
          <Link to='/admin/deals'>
            <Widget
              icon={<FaHandshake className='h-6 w-6' />}
              title={'Deals'}
              subtitle={statistics.deal_count}
            />
          </Link>
        </m.div>
        <m.div
          variants={isAnimationEnabled && dashboardElementVariant}
          className='lg:col-span-2'
        >
          <Link to='/admin/suppliers'>
            <Widget
              icon={<MdBarChart className='h-7 w-7' />}
              title={'Suppliers'}
              subtitle={statistics.client_count}
            />
          </Link>
        </m.div>
        <m.div
          variants={isAnimationEnabled && dashboardElementVariant}
          className='lg:col-span-2'
        >
          <Link to='/admin/users'>
            <Widget
              icon={<FaUsers className='h-7 w-7' />}
              title={'Users'}
              subtitle={statistics.user_count}
            />
          </Link>
        </m.div>
        <m.div
          variants={isAnimationEnabled && dashboardElementVariant}
          className='lg:col-span-6'
        >
          <TotalSpent data={statistics.expenses} />
        </m.div>
        <m.div
          variants={isAnimationEnabled && dashboardElementVariant}
          className='lg:col-span-6'
        >
          <TopWebsites initialData={statistics.websites} />
        </m.div>
        <m.div
          variants={isAnimationEnabled && dashboardElementVariant}
          className='lg:col-span-4'
        >
          <TopClients
            data={statistics.top_clients}
            total={statistics.client_count}
          />
        </m.div>
        <m.div
          variants={isAnimationEnabled && dashboardElementVariant}
          className='lg:col-span-5'
        >
          <DealGrowth data={statistics.deal_growth} />
        </m.div>
        <m.div
          variants={isAnimationEnabled && dashboardElementVariant}
          className='lg:col-span-3'
        >
          <PieChartCard data={statistics.deals} />
        </m.div>
      </m.div>
    </div>
  );
};

export default Dashboard;
