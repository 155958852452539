import React from 'react';

export default function CurrencySelector({
  onCurrencyChange = null,
  current = 'EUR',
}) {
  const handleSelectChange = (e, currency) => {
    e.preventDefault();
    const selectedCurrency = currency;
    onCurrencyChange(selectedCurrency);
  };
  return (
    <div>
      <div className='pb-2 text-sm text-gray-700'>Currency</div>
      <div className='grid grid-cols-4 gap-2'>
        <button
          onClick={(e) => handleSelectChange(e, 'EUR')}
          className={`rounded-lg border border-indigo-100 py-1 text-indigo-800 ${
            current === 'EUR' ? 'bg-indigo-100' : ''
          }`}
        >
          EUR
        </button>
        <button
          onClick={(e) => handleSelectChange(e, 'USD')}
          className={`rounded-lg border border-indigo-100 py-1 text-indigo-800 ${
            current === 'USD' ? 'bg-indigo-100' : ''
          }`}
        >
          USD
        </button>
        <button
          onClick={(e) => handleSelectChange(e, 'DKK')}
          className={`rounded-lg border border-indigo-100 py-1 text-indigo-800 ${
            current === 'DKK' ? 'bg-indigo-100' : ''
          }`}
        >
          DKK
        </button>
        <button
          onClick={(e) => handleSelectChange(e, 'JPY')}
          className={`rounded-lg border border-indigo-100 py-1 text-indigo-800 ${
            current === 'JPY' ? 'bg-indigo-100' : ''
          }`}
        >
          JPY
        </button>
      </div>
    </div>
  );
}
