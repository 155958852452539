import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';

import { ThemeProvider } from '@material-tailwind/react';
import { StoreProvider } from 'easy-peasy';
import { easyPeasyStore } from 'store';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StoreProvider store={easyPeasyStore}>
    <BrowserRouter>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </StoreProvider>,
);
