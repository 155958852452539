import { Input } from '@material-tailwind/react';
import Modal from 'components/modals/Modal';
import { useStoreActions } from 'easy-peasy';
import ErrorHelper from 'helpers/ErrorHelper';
import { defaultToastOptions } from 'options';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import CountryService from 'services/CountryService';

const HelperText = ({ text }) => {
  return <p className='mt-1 text-xs text-red-500'>{text}</p>;
};

const EditModal = ({ modelId = null }) => {
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  const { data, isFetching, isError } = useQuery(
    ['countries', modelId],
    async () => {
      return await CountryService.get(modelId);
    },
    {
      enabled: modelId != null,
      onSuccess: (data) => {
        setValue('name', data.countryname);
        setValue('countryCode', data.countrycode);
        setValue('code', data.code);
      },
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm();
  const queryClient = useQueryClient();

  const updateCountryMutation = useMutation((data) =>
    CountryService.update(modelId, data),
  );

  const onSubmit = () => {
    if (!isDirty) {
      closeModal();
      return;
    }
    const data = getValues();
    const toastId = toast.loading('Updating market...');
    updateCountryMutation.mutate(
      {
        countryname: data.name,
        countrycode: data.countryCode,
        code: data.code,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('countries');
          closeModal();
          toast.update(toastId, {
            render: 'Country updated successfully',
            type: 'success',
            ...defaultToastOptions,
          });
        },
        onError: (error) => {
          if (error.response && error.response.status === 422) {
            return ErrorHelper.handleValidationErrors(
              error.response.data.errors,
              toastId,
            );
          }
          ErrorHelper.handleApiError(error.response.status, toastId);
        },
      },
    );
  };

  return (
    <>
      <Modal
        isOpen={!isFetching}
        title='Edit market'
        buttons={
          <div className='flex justify-end gap-2'>
            <button
              onClick={(e) => {
                closeModal();
              }}
              className='linear w-1/2 rounded-xl px-5 py-2.5 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100'
            >
              Cancel
            </button>
            <button
              onClick={onSubmit}
              className='linear w-1/2 rounded-xl bg-indigo-500 px-5 py-2.5 text-base font-medium text-white transition duration-200 hover:bg-indigo-600'
            >
              {updateCountryMutation.isLoading ? 'Saving...' : 'Save'}
            </button>
          </div>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-[20px] flex flex-col gap-y-4'>
            <div>
              <Input
                className=''
                variant='standard'
                label='Name'
                color={`${errors.name ? 'red' : 'indigo'}`}
                labelProps={{ className: 'text-gray-700' }}
                {...register('name', {
                  required: true,
                  minLength: 3,
                  maxLength: 50,
                })}
              />
              {errors.name && errors.name.type === 'required' && (
                <HelperText text='Name is required' />
              )}
              {errors.name && errors.name.type === 'minLength' && (
                <HelperText text='Name must be at least 3 characters' />
              )}
            </div>
            <div>
              <Input
                className=''
                variant='standard'
                label='Country code'
                color={`${errors.code ? 'red' : 'indigo'}`}
                labelProps={{ className: 'text-gray-700' }}
                {...register('countryCode', {
                  required: true,
                  minLength: 3,
                  maxLength: 3,
                })}
              />
              {errors.countryCode && errors.countryCode.type === 'required' && (
                <HelperText text='Country code is required' />
              )}
              {errors.countryCode &&
                errors.countryCode.type === 'minLength' && (
                  <HelperText text='Country code must be at least 3 characters' />
                )}
            </div>
            <div>
              <Input
                className=''
                variant='standard'
                label='Code'
                color={`${errors.code ? 'red' : 'indigo'}`}
                labelProps={{ className: 'text-gray-700' }}
                {...register('code', {
                  required: true,
                  minLength: 2,
                  maxLength: 2,
                })}
              />
              {errors.code && errors.code.type === 'required' && (
                <HelperText text='Code is required' />
              )}
              {errors.code && errors.code.type === 'minLength' && (
                <HelperText text='Code must be at least 2 characters' />
              )}
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default EditModal;
