import ApiService from './ApiService';

const CountryService = {
  getAll: async (page = 1, query = '') => {
    const response = await ApiService.get(
      `/countries?search=${query}&page=${page}`,
    );
    return response.data;
  },
  bulkDelete: async (ids) => {
    const response = await ApiService.post('countries/bulk-delete', {
      ids,
    });
    return response.data;
  },
  delete: async (id) => {
    const response = await ApiService.delete('countries/' + id);
    return response.data;
  },
  get: async (id) => {
    const response = await ApiService.get('countries/' + id);
    return response.data;
  },
  create: async (data) => {
    const response = await ApiService.post('countries', data);
    return response.data;
  },
  update: async (id, data) => {
    const response = await ApiService.put('countries/' + id, data);
    return response.data;
  },
};

export default CountryService;
