import Card from 'components/card';
import Switch from 'components/switch';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import ApiService from 'services/ApiService';

const ProfileInformation = ({ data }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    clearErrors,
    getValues,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm();

  const queryClient = useQueryClient();

  useEffect(() => {
    setValue('name', data.name);
    setValue('email', data.email);
    setValue('allow_notifications', data.allow_notifications);
  }, [data]);

  const mutation = useMutation(async () => {
    ApiService.patch('profile', {
      name: getValues('name'),
      email: getValues('email'),
      allow_notifications: getValues('allow_notifications'),
    });
  });

  const submit = () => {
    const toastId = toast.loading('Updating profile information...');
    mutation.mutate(null, {
      onSuccess: () => {
        toast.update(toastId, {
          type: 'success',
          render: 'Profile information updated!',
          isLoading: false,
          autoClose: 3000,
        });
        reset(getValues());
        queryClient.invalidateQueries(['profile']);
      },
      onError: () => {
        toast.update(toastId, {
          type: 'error',
          render: 'Profile information could not be updated!',
          isLoading: false,
          autoClose: 3000,
        });
      },
    });
  };

  return (
    <Card extra={'w-full h-full p-4'}>
      <div className='mb-6 text-xl font-bold'>Profile information</div>
      <div className='flex flex-col gap-4 text-[16px]'>
        <div className='flex flex-col justify-center gap-2 rounded-2xl border bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <span className='text-gray-500'>Name</span>
          <input
            {...register('name', {
              required: true,
            })}
            type='text'
            placeholder='Enter name...'
            className='rounded-lg border bg-lightPrimary p-3 outline-0 transition focus:border-brand-300'
          />
        </div>
        <div className='flex flex-col justify-center gap-2 rounded-2xl border bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <span className='text-gray-500'>Email</span>
          <input
            {...register('email', {
              required: true,
            })}
            type='text'
            placeholder='Enter name...'
            className='rounded-lg border bg-lightPrimary p-3 outline-0 transition focus:border-brand-300'
          />
        </div>
        <div className='flex flex-col justify-center gap-2 rounded-2xl border bg-white bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <span className='text-gray-500'>Notifications</span>
          <span>
            <Controller
              name='allow_notifications'
              control={control}
              defaultValue={getValues('allow_notifications')}
              value={getValues('allow_notifications')}
              render={({ field }) => (
                <Switch
                  {...field}
                  checked={field.value}
                  extra='my-2'
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </span>
        </div>
        <div>
          <button
            className={`w-full rounded-xl bg-brand-100 px-4 py-4 text-brand-700 transition ${
              !isDirty && 'opacity-0'
            }`}
            disabled={!isDirty}
            onClick={submit}
          >
            Update
          </button>
        </div>
      </div>
    </Card>
  );
};

export default ProfileInformation;
