import { Input } from '@material-tailwind/react';
import Modal from 'components/modals/Modal';
import { useStoreActions } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import UnitService from 'services/UnitService';

const HelperText = ({ text }) => {
  return <p className='mt-1 text-xs text-red-500'>{text}</p>;
};

const EditUnit = ({ modelId = null }) => {
  const closeModal = useStoreActions((actions) => actions.modal.closeModal);

  const { data, isFetching, isError } = useQuery(
    ['units', modelId],
    async () => {
      return await UnitService.get(modelId);
    },
    {
      enabled: modelId != null,
      onSuccess: (data) => {
        setValue('name', data.name);
      },
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty },
  } = useForm();
  const queryClient = useQueryClient();

  const updateUnitMutation = useMutation((data) =>
    UnitService.update(modelId, data),
  );

  const onSubmit = () => {
    if (!isDirty) {
      closeModal();
      return;
    }
    const data = getValues();
    updateUnitMutation.mutate(
      {
        name: data.name,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('units');
          closeModal();
          toast.success('Unit updated successfully');
        },
      },
    );
  };

  return (
    <>
      <Modal
        title='Edit Unit'
        buttons={
          <div className='flex justify-end gap-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                closeModal();
              }}
              className='linear w-1/2 rounded-xl px-5 py-2.5 text-base font-medium text-gray-700 transition duration-200 hover:bg-gray-100'
            >
              Cancel
            </button>
            <button
              onClick={onSubmit}
              className='linear w-1/2 rounded-xl bg-indigo-500 px-5 py-2.5 text-base font-medium text-white transition duration-200 hover:bg-indigo-600'
            >
              {updateUnitMutation.isLoading ? 'Saving...' : 'Save'}
            </button>
          </div>
        }
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-[20px] flex flex-col gap-y-4'>
            <div>
              <Input
                className=''
                variant='standard'
                label='Name'
                color={`${errors.name ? 'red' : 'indigo'}`}
                labelProps={{ className: 'text-gray-700' }}
                {...register('name', {
                  required: true,
                  minLength: 3,
                  maxLength: 50,
                })}
              />
              {errors.name && errors.name.type === 'required' && (
                <HelperText text='Name is required' />
              )}
              {errors.name && errors.name.type === 'minLength' && (
                <HelperText text='Name must be at least 3 characters' />
              )}
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};
export default EditUnit;
