import { Dropzone, FileMosaic } from '@files-ui/react';
import { Option, Select, Textarea, Tooltip } from '@material-tailwind/react';
import { FileUploadRounded } from '@mui/icons-material';
import axios from 'axios';
import Card from 'components/card';
import DatePicker from 'components/inputs/DatePicker';
import Loading from 'components/loading/Loading';
import Switch from 'components/switch';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { hasPermissionTo } from 'helpers/AuthHelper';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaHandshake } from 'react-icons/fa';
import { IoClose, IoSunnyOutline } from 'react-icons/io5';
import { RiStackFill } from 'react-icons/ri';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApiService from 'services/ApiService';

const currencies = [
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'DKK',
    label: 'DKK',
  },
  {
    value: 'GBP',
    label: 'GBP',
  },
  {
    value: 'JPY',
    label: 'JPY',
  },
  {
    value: 'CHF',
    label: 'CHF',
  },
];

export default function EditForm({ getStatus = null }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);
  const [linkFiles, setLinkFiles] = useState([]);
  const [invoiceFiles, setInvoiceFiles] = useState([]);
  const token = useStoreState((state) => state.auth.token);
  const { id } = useParams();
  const queryClient = useQueryClient();

  const {
    data: deal,
    isLoading,
    refetch,
  } = useQuery(
    ['deals', id],
    async () => {
      const res = await ApiService.get(`/deals/${id}`);
      return res.data;
    },
    {
      onSuccess: (data) => {
        setValue(
          'invoice_due',
          data?.invoice_due ? new Date(data.invoice_due) : null,
        );
        setValue('client_id', data?.client_id);
        setValue('company_id', data?.company_id);
        setValue('notes', data?.notes);
      },
      staleTime: 0,
      cacheTime: 0,
    },
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    trigger,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      invoice_due: new Date(),
      invoice: deal?.invoice,
    },
  });

  useEffect(() => {
    setValue(
      'invoice_due',
      deal?.invoice_due ? new Date(deal?.invoice_due) : null,
    );
    setValue('client_id', deal?.client_id);
    setValue('company_id', deal?.company_id);
    setValue('notes', deal?.notes);
    setValue('invoice', deal?.invoice);
    setValue('message_for_finance', deal?.legal_notes);
  }, [deal]);

  const { data: clients } = useQuery('clients', async () => {
    const res = await ApiService.get(
      '/clients?per_page=100&fields[clients]=id,name',
    );
    return res.data;
  });

  const { data: companies } = useQuery('companies', async () => {
    const res = await ApiService.get(
      '/companies?per_page=100&fields[companies]=id,name',
    );
    return res.data;
  });

  const updateDealMutation = useMutation((data) => {
    return ApiService.put(`/deals/${id}`, data);
  });

  const submit = async () => {
    const data = getValues();
    let formData = new FormData();
    formData.append('client_id', data.client_id);
    formData.append('invoice_file', invoiceFiles[0]?.file ?? '');
    if (getValues('invoice_due'))
      formData.append(
        'invoice_due',
        getValues('invoice_due').toISOString() ?? '',
      );
    formData.append('company_id', data.company_id);
    formData.append('notes', data.notes ?? '');
    formData.append('message_for_finance', data.message_for_finance ?? '');
    formData.append('_method', 'PUT');

    let config = {
      method: 'post',
      url: process.env.REACT_APP_API_URL + '/deals/' + id,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + (token ?? null),
      },
      data: formData,
    };

    const updateDealRequest = axios
      .request(config)
      .then((response) => {
        setValue('invoice', response.data.invoice);
        queryClient.invalidateQueries(['deals']);
        refetch();
      })
      .catch((error) => {});
    toast.promise(updateDealRequest, {
      pending: 'Updating deal...',
      success: 'Deal updated successfully',
      error: 'Error updating deal',
    });
  };

  const approveDealMutation = useMutation(
    () =>
      ApiService.post(`/deals/approve`, {
        deals: [id],
        send_copy: getValues('send_copy'),
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('deals');
        refetch();
      },
    },
  );

  const { openModal, closeModal } = useStoreActions((actions) => actions.modal);

  const approveDeals = () => {
    openModal({
      name: 'confirmation',
      props: {
        onClose: () => {
          closeModal();
        },
        onConfirm: () => {
          toast
            .promise(approveDealMutation.mutateAsync(), {
              pending: 'Approving deal...',
              success: 'Deal approved successfully',
              error: 'Error approving deal',
            })
            .finally(() => {
              closeModal();
            });
        },
        title: 'Approve deal',
        message: 'Are you sure you want to approve this deal?',
      },
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Card extra='p-[20px] gap-2 3xl:col-span-2 justify-center'>
        <div className='flex flex-row items-center justify-between gap-4'>
          <div className='flex h-full w-full flex-row items-center justify-between gap-4'>
            <div className='flex w-full flex-col items-center gap-6'>
              <div className='rounded-full bg-indigo-100 p-4'>
                <FaHandshake className='text-5xl text-brand-500' />
              </div>
              <div className='flex flex-col text-center'>
                <div className='flex items-center gap-x-2 text-lg font-bold text-gray-700'>
                  <div className='w-full text-center'>Deal {deal?.id}</div>
                </div>
                <span className='mt-2 text-gray-700'>
                  Created by {deal?.data?.user_name} on 01.2024
                </span>
              </div>
              <div
                className={`flex items-center rounded-lg px-3 py-0.5 text-lg ${
                  deal.status === 'indexed' && 'bg-gray-100 text-gray-700'
                }  ${
                  deal.status === 'upcoming' && 'bg-yellow-100 text-yellow-600'
                }`}
              >
                {deal.status === 'indexed' && (
                  <RiStackFill className='text-2xl' />
                )}
                {deal.status === 'upcoming' && (
                  <IoSunnyOutline className='text-2xl' />
                )}
                <span className={`rounded-lg px-2 py-1 capitalize`}>
                  {deal.status}
                </span>
              </div>
              <div className='flex gap-x-2'>
                {hasPermissionTo('approve deals') &&
                  deal.status == 'indexed' && (
                    <button
                      onClick={approveDeals}
                      className='rounded-lg bg-green-200 px-4 py-2 text-green-600'
                    >
                      Approve Deal
                    </button>
                  )}
                <button
                  onClick={submit}
                  className='rounded-lg bg-brand-500 px-4 py-2 text-white'
                >
                  Update Deal
                </button>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Card extra='p-[20px] 3xl:col-span-4'>
        <p className='mb-6 font-medium text-gray-700'>Deal Information</p>
        <div className='flex flex-col gap-5'>
          {clients && (
            <Select
              color='indigo'
              variant='standard'
              label='Client'
              name='client_id'
              {...register('client_id', {
                required: 'Client is required',
              })}
              onChange={(e) => {
                setValue('client_id', e, {
                  shouldDirty: true,
                });
              }}
              value={getValues('client_id') || deal.client_id || null}
            >
              {clients?.data?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
          {companies && (
            <Select
              color='indigo'
              variant='standard'
              label='Company'
              name='company_id'
              {...register('company_id', {
                required: 'Client is required',
              })}
              onChange={(e) =>
                setValue('company_id', e, {
                  shouldDirty: true,
                })
              }
              value={getValues('company_id') || deal.company_id || null}
            >
              {companies?.data?.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          )}
          <Controller
            control={control}
            name='invoice_due'
            defaultValue={null}
            render={({ field: { onChange, onBlur, value, ref } }) => {
              return (
                <DatePicker
                  onChange={onChange}
                  onBlur={onBlur}
                  label='Set invoice due date'
                  value={value ?? ''}
                />
              );
            }}
          />
          <div className='flex flex-col gap-1'>
            <span className='text-xs'>Invoice</span>
            {getValues('invoice') ? (
              <div className='flex items-center justify-between gap-x-2 rounded-lg border px-3 py-2'>
                <a
                  href={deal.invoice}
                  className='rounded-md bg-gray-200 px-2 py-1 text-sm'
                  target='_blank'
                  rel='noreferrer'
                >
                  View invoice file
                </a>
                <Tooltip content='Delete file'>
                  <button className='rounded-full bg-gray-200 p-0.5'>
                    <IoClose
                      onClick={() => {
                        setValue('invoice', null, {
                          shouldDirty: true,
                        });
                      }}
                      className='text-sm text-gray-800'
                    />
                  </button>
                </Tooltip>
              </div>
            ) : (
              <Dropzone
                onChange={(files) => setInvoiceFiles(files)}
                value={invoiceFiles}
                maxFiles={1}
                header={false}
                footer={false}
                behaviour='replace'
                label={
                  <div className='flex items-center gap-2'>
                    <FileUploadRounded />
                    <div>Upload invoice here...</div>
                  </div>
                }
                accept='application/pdf'
                className='!min-h-[80px] border-none !border-gray-300 bg-lightPrimary !font-sans !text-lg'
              >
                {invoiceFiles.map((file, index) => (
                  <FileMosaic key={index} {...file} preview />
                ))}
              </Dropzone>
            )}
          </div>
        </div>
      </Card>
      <Card extra='p-[20px] flex flex-col gap-4 3xl:col-span-4'>
        <p className='mb-6 font-medium text-gray-700'>Additional Information</p>
        <div>
          <Textarea
            color='indigo'
            {...register('notes')}
            label='Notes'
            variant='static'
            className='bg-lightPrimary/50 px-2'
            placeholder='Add notes here...'
          />
        </div>
        <div>
          <Textarea
            color='indigo'
            {...register('message_for_finance')}
            label='Message for finance'
            variant='static'
            className='bg-lightPrimary/50 px-2'
            placeholder='Add message here...'
          />
        </div>
        <div className='flex flex-row items-center gap-2'>
          <Controller
            name='send_copy'
            control={control}
            defaultValue={getValues('send_copy')}
            value={getValues('send_copy')}
            render={({ field }) => (
              <Switch
                {...field}
                checked={field.value}
                extra='my-2'
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
          <p>Send me a copy</p>
        </div>
      </Card>
    </>
  );
}
