import React from 'react';
import Dropdown from 'components/dropdown';
import { BsThreeDots } from 'react-icons/bs';

function ChartDropdown(props) {
  const { transparent, children, onChangeTimeFrame } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <Dropdown
      button={
        <button
          onClick={() => setOpen(!open)}
          open={open}
          className={`flex items-center text-xl hover:cursor-pointer ${
            transparent
              ? 'bg-none text-white hover:bg-none active:bg-none'
              : 'bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10'
          } linear justify-center rounded-lg font-bold transition duration-200`}
        >
          <BsThreeDots className='h-6 w-6' />
        </button>
      }
      animation={'origin-top-right transition-all duration-300 ease-in-out'}
      classNames={`${transparent ? 'top-8' : 'top-11'} right-0 w-max z-50`}
      children={
        <div className='z-50 w-max rounded-xl border bg-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none'>
          <div className='flex flex-col text-gray-700'>
            <button
              onClick={() => {
                setOpen(false);
                onChangeTimeFrame('last-month');
              }}
              className='rounded px-2 py-0.5 text-right transition hover:bg-gray-100 hover:dark:bg-navy-800'
            >
              Last month
            </button>
            <button
              onClick={() => {
                setOpen(false);
                onChangeTimeFrame('last-6-months');
              }}
              className='rounded px-2 py-0.5 text-right transition hover:bg-gray-100 hover:dark:bg-navy-800'
            >
              Last 6 months
            </button>
            <button
              onClick={() => {
                setOpen(false);
                onChangeTimeFrame('last-12-months');
              }}
              className='rounded px-2 py-0.5 text-right transition hover:bg-gray-100 hover:dark:bg-navy-800'
            >
              Last 12 months
            </button>
            <button
              onClick={() => {
                setOpen(false);
                onChangeTimeFrame('all-time');
              }}
              className='rounded px-2 py-0.5 text-right transition hover:bg-gray-100 hover:dark:bg-navy-800'
            >
              All time
            </button>
          </div>
        </div>
      }
    />
  );
}

export default ChartDropdown;
